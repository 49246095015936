import {
	CircularProgress,
	MenuItem,
	Select,
	useMediaQuery,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {
	setHaveLegacySubscription,
	setSelectedInvoiceCategory,
} from '../../redux/slices/plans.slice';
import SubscriptionsPlanSection from './SubscriptionsPlanSection';
import { useEffect, useState } from 'react';
import { addAcquisitions } from '../../redux/slices/products.slice';
import RecommendedPlanSection from './RecommendedPlanSection';
import { IOnboardingResponseSamantha } from '../../@types/IA.type';
import {
	getOnboardingAnalysisIA,
	getOnboardingIA,
} from '../../services/heru-ai.service';
import AsatPlanSection from './AsatPlanSection';
import RegularizationsPlanSection from './RegularizationsPlanSection';
import {
	setOnboarding,
	setSamanthaAnalysis,
} from '../../redux/slices/ai.slice';
import OnboardingPlanSection from './OnboardingPlanSection';
import { fetchSATLinkedById } from '../../services/satLinking.service';
import { linkedAccount } from '../../redux/slices/sat.slice';
import { getUserSuscriptions } from '../../services/suscription.service';
import { setUserSubscriptions } from '../../redux/slices/profile.slice';
import { fetchAcquisitions } from '../../services/shopping.service';
import { PRODUCTS_BY_SLUG } from '../plan/data/ecommerceData';
import { getOrderRefresh } from '../../services/orders.service';
import { setRefreshOrders } from '../../redux/slices/cart.slice';
import ModalCancelPendingOrder from './organisms/ModalCancelPendingOrder';
import { useSearchParams } from 'react-router-dom';
import URL_PARAMS from '../../shared/constants/urlParams';
import AnnualPlanSection from './AnnualPlanSection';

enum PLAN_CATEGORY {
	MONTHLY_DECLARATIONS = 'monthly_declarations',
	REGULARIZATIONS = 'regularizations',
	ANNUAL_DECLARATIONS = 'annual_declarations',
	ASAT = 'asat',
}

const PLANS_CATEGORIES = [
	{
		label: 'Contabilidad Heru',
		slug: PLAN_CATEGORY.MONTHLY_DECLARATIONS,
	},
	{
		label: 'Declaración anual 2025',
		slug: PLAN_CATEGORY.ANNUAL_DECLARATIONS,
	},
	{
		label: 'Declaraciones atrasadas',
		slug: PLAN_CATEGORY.REGULARIZATIONS,
	},
	{
		label: 'Trámites fiscales SAT',
		slug: PLAN_CATEGORY.ASAT,
	},
];

const PlansAndPricesV2 = () => {
	const dispatch = useDispatch();

	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { activeSuscriptions, subscriptions } = useSelector(
		(state: RootState) => state.profile,
	);
	const { selectedInvoiceCategory } = useSelector(
		(state: RootState) => state.plans,
	);

	const [showSubscriptionsSection, setShowSubscriptionsSection] =
		useState(false);
	const [showLegacyPlans, setShowLegacyPlans] = useState(false);
	const [showRecommendedPlan, setShowRecommendedPlan] = useState(true);
	const [isLoadingServices, setIsLoadingServices] = useState(true);
	const { onboarding, samanthaAnalysis } = useSelector(
		(state: RootState) => state.ai,
	);
	const [searchParams] = useSearchParams();

	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const handleCategory = (slug: string) => {
		dispatch(setSelectedInvoiceCategory(slug));
	};

	const getUserOrder = async () => {
		try {
			const params = `?status=pending`;
			const orders = await getOrderRefresh(params, tokenSession);
			dispatch(setRefreshOrders(orders));
			return orders;
		} catch (error) {
			return error;
		}
	};
	const fetchUserSuscriptions = async () => {
		try {
			const subs = await getUserSuscriptions(tokenSession);
			dispatch(setUserSubscriptions(subs));
		} catch (error) {}
	};

	const getAcquisitions = async () => {
		try {
			const response = await fetchAcquisitions(tokenSession);
			dispatch(addAcquisitions(response));
		} catch (error) {}
	};

	const fetchSatLinkedAcount = async () => {
		try {
			const resource = await fetchSATLinkedById(tokenSession);
			dispatch(linkedAccount(resource));
		} catch (error) {}
	};

	const getOnboardingAI = async () => {
		try {
			const onboarding: IOnboardingResponseSamantha = await getOnboardingIA(
				userProfile?.id!,
			);

			dispatch(setOnboarding(onboarding));
			return onboarding;
		} catch (error) {}
	};

	const getAnalysisAI = async (onboardingId: number) => {
		const userId = userProfile?.id;
		if (!userId || !onboardingId) return;

		try {
			const response = await getOnboardingAnalysisIA(userId, onboardingId);
			dispatch(setSamanthaAnalysis(response));
			return response;
		} catch (error) {}
	};

	const isMobile = useMediaQuery('(max-width: 768px)');

	useEffect(() => {
		if (userProfile && userProfile?.id) {
			(async () => {
				await fetchSatLinkedAcount();
				await fetchUserSuscriptions();
				await getAcquisitions();
				await getUserOrder();
				const onboardingInit = await getOnboardingAI();
				if (onboardingInit?.percentage?.percentage === 100) {
					await getAnalysisAI(onboardingInit?.userCurrentData?.id!);
				}
				setIsLoadingServices(false);
			})();
		}
	}, [userProfile]);

	useEffect(() => {
		if (activeSuscriptions) {
			const legacySubscriptions = [
				PRODUCTS_BY_SLUG.HERU_PLUS,
				PRODUCTS_BY_SLUG.ACTIVIDAD_EMPRESARIAL,
				PRODUCTS_BY_SLUG.RESICO,
				PRODUCTS_BY_SLUG.PLATAFORMAS_TECNOLOGICAS,
				PRODUCTS_BY_SLUG.ARR_MONTHLY,
			];
			const userHaveLegacySubscription = activeSuscriptions?.some((sub) =>
				legacySubscriptions.includes(
					sub.product_version?.product?.slug as PRODUCTS_BY_SLUG,
				),
			);
			dispatch(setHaveLegacySubscription(userHaveLegacySubscription));
		}
	}, [activeSuscriptions]);

	const viewSubscriptionsSection =
		onboarding?.percentage?.percentage === 100 ||
		showSubscriptionsSection ||
		subscriptions?.length > 0;

	const haveParams =
		searchParams.get(URL_PARAMS.FLOW) !== null ||
		searchParams.get(URL_PARAMS.FLOW_PERIODICITY) !== null;

	if (isLoadingServices) {
		return (
			<div className='flex items-center justify-center mt-10'>
				<CircularProgress size={25} />
			</div>
		);
	}

	if (
		showRecommendedPlan &&
		samanthaAnalysis?.slug &&
		samanthaAnalysis?.slug !== 'cant_recommend_plan' &&
		!activeSuscriptions.length &&
		!haveParams
	)
		return (
			<RecommendedPlanSection setShowRecommendedPlan={setShowRecommendedPlan} />
		);

	return (
		<section className='md:py-6 md:px-12 flex flex-col gap-4 pb-10 bg-h-neutral-94'>
			<ModalCancelPendingOrder />
			<div className='px-3 w-full mt-2'>
				{isMobile ? (
					<Select
						size='small'
						value={selectedInvoiceCategory}
						onChange={(e) =>
							dispatch(setSelectedInvoiceCategory(e.target.value as string))
						}
						className='w-full'
					>
						{PLANS_CATEGORIES.map((category, index) => (
							<MenuItem
								value={category.slug}
								key={`category-${index}`}
							>
								{category.label}
							</MenuItem>
						))}
					</Select>
				) : (
					<div className='flex justify-center p-1.5 gap-2 rounded-full bg-white w-fit mx-auto border border-h-neutral-90'>
						{PLANS_CATEGORIES.map((category, index) => (
							<button
								key={`category-${index}`}
								onClick={() => handleCategory(category.slug)}
								className={`rounded-full px-4 py-2 ${
									selectedInvoiceCategory === category.slug
										? 'text-white bg-h-primary border border-h-primary-80'
										: 'hover:bg-h-neutral-95'
								}`}
							>
								{category.label}
							</button>
						))}
					</div>
				)}
			</div>

			{selectedInvoiceCategory === PLAN_CATEGORY.MONTHLY_DECLARATIONS && (
				<>
					{viewSubscriptionsSection ? (
						<SubscriptionsPlanSection
							showLegacyPlans={showLegacyPlans}
							setShowLegacyPlans={setShowLegacyPlans}
						/>
					) : (
						<OnboardingPlanSection
							handleSkip={() => setShowSubscriptionsSection(true)}
						/>
					)}
				</>
			)}
			{selectedInvoiceCategory === PLAN_CATEGORY.ANNUAL_DECLARATIONS && (
				<AnnualPlanSection />
			)}
			{selectedInvoiceCategory === PLAN_CATEGORY.REGULARIZATIONS && (
				<RegularizationsPlanSection />
			)}
			{selectedInvoiceCategory === PLAN_CATEGORY.ASAT && <AsatPlanSection />}
		</section>
	);
};

export default PlansAndPricesV2;

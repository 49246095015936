import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FiscalProfileCard from '../FiscalProfileCard';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
	addTaxPayer,
	startLoader,
} from '../../../redux/slices/taxProfile.slice';
import { RootState } from '../../../redux/store';
import {
	fetchMonthlyDeclarationOverview,
	fetchTaxPayer,
	fetchYearlyDeclarationsOverview,
} from '../../../services/taxes.service';
import FiscalDocShare from '../../molecules/FiscalDocShare';

import InvoicesSumary from '../InvoicesSumary';
import {
	fetchCheckUserRappi,
	fetchFinancialsMovements,
} from '../../../services/invoicing.service';
import { IFiscalStatus, MovementDocuments } from '../../../@types/Fiscal.type';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../shared/constants/routes';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { isSessionStorageEnabled } from '../../../shared/utils/general.util';
import { PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import { DATES } from '../../../shared/utils/dates';
import bannerSubscriptions from '../../../assets/img/banners/Suscribete_Small_Desktop.png';
import bannerAnnualPromo from '../../../assets/img/png/banner-annual-50.png';
import fiscalComplianceBanner from '../../../assets/img/banners/Conoce_planes_Small_Desktop.png';
import onboardingBanner from '../../../assets/img/banners/finish_onboarding.png';
import ServicesStatuHome from '../ServicesStatuHome';
import { settingsCarrousel } from '../SATLinking/SATLinking';
import LOCAL_STORAGE_KEY from '../../../shared/constants/localStorageKey';
import { setOverwriteUtm, updateUTMs } from '../../../redux/slices/cart.slice';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import BlockedMock from '../ServicesStatuHome/BlockedMock';

import { REGIMES_CODE } from '../../../@types/Regime.type';
import MyFiscalStatus from '../MyFiscalStatus';
import LastPendingOrder from '../../../pages/pendingReferences/components/LastPendingOrder';
import { VARIANT_STATUS } from '../../../shared/constants/serviceStatus';

import DeclarationStatusFFT from '../DeclarationStatusFFT';
import { FftItem } from '../../../@types/FFT.type';
import { CircularProgress } from '@mui/material';

import PromotionalBanner from '../../../pages/home/molecules/PromotionalBanner';

interface Props {
	declarationStatus?: FftItem;
	isLoadingDeclarationStatus?: boolean;
	showLucyView: boolean;
}
import { PRODUCTS_BY_SLUG } from '../../../pages/plan/data/ecommerceData';
import Modal from '../../molecules/Modal/Modal';

type TAnnualObligations = {
	year: number;
	status: string;
};

// let loop = 0;
const LoadingComponent = () => {
	return (
		<div className={'bg-white flex justify-center p-6 rounded-lg shadow-md'}>
			<CircularProgress />
		</div>
	);
};

type BannerItem = {
	src: string;
	alt: string;
	onClick: () => void;
};

const UserCredentialsLinked: React.FC<Props> = ({
	declarationStatus,
	isLoadingDeclarationStatus,
	showLucyView,
}) => {

	const [showModal, setShowModal] = useState({
		fiscalCompliance: false,
		annualArticle: false,
	});
	const [invoices, setInvoices] = useState<MovementDocuments[]>([]);
	const [isRappiUser, setIsRappiUser] = useState({
		status: false,
		isLoading: true,
	});

	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);
	const { user: userProfile, serviceStatus } = useSelector(
		(state: RootState) => state.profile,
	);
	const { activeSuscriptions, hasPendingAnalysis } = useSelector(
		(state: RootState) => state.profile,
	);

	const legacySubscriptions = [
		PRODUCTS_BY_SLUG.HERU_PLUS,
		PRODUCTS_BY_SLUG.ACTIVIDAD_EMPRESARIAL,
		PRODUCTS_BY_SLUG.RESICO,
		PRODUCTS_BY_SLUG.PLATAFORMAS_TECNOLOGICAS,
		PRODUCTS_BY_SLUG.ARR_MONTHLY,
		PRODUCTS_BY_SLUG.PLAN_ENTREPRENEUR,
		PRODUCTS_BY_SLUG.PLAN_PLATFORM,
		PRODUCTS_BY_SLUG.PLAN_BUSINESS,
	];

	const userHaveSubscription = activeSuscriptions?.some((sub) =>
		legacySubscriptions?.includes(
			sub.product_version?.product?.slug as PRODUCTS_BY_SLUG,
		),
	);

	const userHaveAnnualSubscription = activeSuscriptions?.some(
		(sub) =>
			sub.product_version?.product?.slug === PRODUCTS_SLUG.ANNUAL_DECLARATION,
	);

	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const [fiscalStatus, setFiscalStatus] = useState<IFiscalStatus>();
	const logClevertapEvent = useCleverTapEvent();

	const activeServices = useMemo(() => {
		return serviceStatus?.filter((it) => it?.status !== 'closed') || [];
	}, [serviceStatus]);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	console.log('showLucyView linking', showLucyView);

	const getTaxPayer = async () => {
		try {
			if (!userProfile?.id) return;
			dispatch(startLoader(true));
			const resource = await fetchTaxPayer(userProfile?.id, tokenSession);
			dispatch(addTaxPayer(resource));
		} catch (error: any) {
			dispatch(startLoader(false));
		}
	};

	const getIsRappiUser = useCallback(async () => {
		if (!userProfile?.id) return;
		try {
			const resource = await fetchCheckUserRappi(
				userProfile?.id!,
				tokenSession,
			);
			setIsRappiUser({
				status: resource?.length > 0,
				isLoading: false,
			});
		} catch (error) {
			setIsRappiUser({ status: false, isLoading: false });
		}
	}, [userProfile]);

	const getFinancialsMovements = async () => {
		try {
			//Todo: Check if user is Rappi User
			const extraParams = `&rappi=${isRappiUser.status}`;
			const params = `limit=${5}${extraParams}`;
			const _invoices: MovementDocuments[] = await fetchFinancialsMovements(
				params,
				tokenSession,
			);
			setInvoices(_invoices);
		} catch (error) {}
	};

	useEffect(() => {
		if (taxPayer) return;
		getTaxPayer();
	}, [taxPayer, userProfile?.id]);

	useEffect(() => {
		getIsRappiUser();
	}, [userProfile]);

	useEffect(() => {
		if (isRappiUser.isLoading) return;
		getFinancialsMovements();
	}, [isRappiUser]);

	const getAnnualsPeriod = async () => {
		if (!taxPayer) return;
		let totalYears = 5;
		if (taxPayer?.registered_at) {
			const utcDate = new Date(taxPayer?.registered_at).getUTCFullYear();
			totalYears =
				DATES.currentYear - utcDate > 5 ? 5 : DATES.currentYear - utcDate;
		}
		const years = Array.from(
			{ length: totalYears },
			(_, i) => DATES.currentYear - 1 - i,
		);

		try {
			Promise.all([
				fetchYearlyDeclarationsOverview(userProfile?.id!),
				fetchMonthlyDeclarationOverview(userProfile?.id!, DATES.currentYear),
			])
				.then((response) => {
					if (!response) return;
					if (response[0] && response[1]) {
						setFiscalStatus({
							annual: response[0].filter((o: TAnnualObligations) =>
								years.includes(o.year),
							),
							monthly: response[1].declarations
								.sort(
									(a: { month: number }, b: { month: number }) =>
										b.month - a.month,
								)
								.slice(0, 5),
						});
					} else if (response[0] && !response[1]) {
						setFiscalStatus({
							annual: response[0].filter((o: TAnnualObligations) =>
								years.includes(o.year),
							),
							monthly: [],
						});
					} else if (!response[0] && response[1]) {
						setFiscalStatus({
							annual: [],
							monthly: response[1].declarations
								.sort(
									(a: { month: number }, b: { month: number }) =>
										b.month - a.month,
								)
								.slice(0, 5),
						});
					}
				})
				.catch(() => {
					setFiscalStatus({
						annual: [],
						monthly: [],
					});
				});
		} catch (error) {
			setFiscalStatus({
				annual: [],
				monthly: [],
			});
		}
	};

	useEffect(() => {
		getAnnualsPeriod();
	}, [taxPayer]);

	const logBannerHomeEvent = (banner: 'planes y precios' | 'suscripcion') => {
		logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
			event_type: EVENT_TYPES.HOME_PURCHASE_BANNER_INTERACTION,
			id: userProfile?.id!,
			phone: userProfile?.cellphone!,
			banner,
		});
	};

	const fiscalSituationStatus = useMemo(() => {
		if (!taxPayer?.status?.status) return 'pending';
		if (
			taxPayer?.status?.status === 'ACTIVO' ||
			taxPayer?.status?.status === 'REACTIVADO'
		)
			return 'positive';
		return 'negative';
	}, [taxPayer]);

	const complianceOpinion = useMemo(() => {
		if (!taxPayer?.compliance?.result) return 'pending';
		if (taxPayer?.compliance.result === 'positive') return 'positive';
		return 'negative';
	}, [taxPayer]);

	const goToPlans = () => {
		logBannerHomeEvent('planes y precios');
		if (isSessionStorageEnabled()) {
			const overwriteUtmKey = LOCAL_STORAGE_KEY.OVERWRITE_UTM;

			let overwriteUtm = sessionStorage.getItem(overwriteUtmKey);

			if (overwriteUtm === null) {
				sessionStorage.setItem(overwriteUtmKey, 'true');
				overwriteUtm = 'true';
			}
		} else {
			const currentOverwriteUtm = useSelector(
				(state: RootState) => state.cart.overwriteUtm,
			);

			if (currentOverwriteUtm === undefined || currentOverwriteUtm === null) {
				dispatch(setOverwriteUtm(true));
			}

			if (currentOverwriteUtm === true) {
			} else {
				const currentUTMs = useSelector(
					(state: RootState) => state.cart.utmInfo,
				);

				if (!currentUTMs) return;
				const updatedUTMs = {
					...currentUTMs,
					utm_campaign: currentUTMs?.utm_campaign,
					utm_medium: currentUTMs?.utm_medium,
					utm_source: currentUTMs?.utm_source,
				};
				dispatch(updateUTMs(updatedUTMs));
			}
		}

		const params = hasPendingAnalysis ? '?show=analisis' : '';
		navigate(`${ROUTES.PLANS_AND_PRICES}${params}`);
	};

	const compliceOpinionLabel = useMemo(() => {
		if (taxPayer?.compliance?.result) {
			if (taxPayer?.compliance.result === 'negative') return 'Negativa';
			return 'Positiva';
		}
		return 'No disponible';
	}, [taxPayer]);

	const serviceStatusSubscription = serviceStatus?.find(
		(service) =>
			service.current_service_has_step.status === VARIANT_STATUS.BLOCKED &&
			service.current_service_has_step.stage.slug ===
				'stage_subscription_blocked_for_payment_declaration',
	);

	const getActiveBanners = (): BannerItem[] => {
		const banners: BannerItem[] = [];

		banners.push({
			src: fiscalComplianceBanner,
			alt: 'Banner de cumplimiento fiscal',
			onClick: () => setShowModal({ fiscalCompliance: true, annualArticle: false }),
		});

		if (!userHaveSubscription) {
			banners.push({
				src: bannerSubscriptions,
				alt: 'Banner de compra suscripcion',
				onClick: goToPlans,
			});
		}

		if (!userHaveAnnualSubscription) {
			banners.push({
				src: bannerAnnualPromo,
				alt: 'Banner de compra suscripcion anual',
				onClick: () => {
					setShowModal({ fiscalCompliance: false, annualArticle: true });
				},
			});
		}

		if (hasPendingAnalysis) {
			banners.push({
				src: onboardingBanner,
				alt: 'Completa tu análisis fiscal',
				onClick: goToPlans,
			});
		}

		return banners;
	};

	const renderBanners = () => {
		const activeBanners = getActiveBanners();

		if (activeBanners.length === 0) return null;

		if (activeBanners.length === 1) {
			const banner = activeBanners[0];
			return (
				<img
					onClick={banner.onClick}
					src={banner.src}
					alt={banner.alt}
					className='relative h-fit-content w-full object-cover rounded-lg cursor-pointer'
				/>
			);
		}

		return (
			<Slider {...settingsCarrousel}>
				{activeBanners.map((banner, index) => (
					<img
						key={index}
						onClick={banner.onClick}
						src={banner.src}
						alt={banner.alt}
						className='relative h-fit-content w-full object-cover rounded-lg cursor-pointer'
					/>
				))}
			</Slider>
		);
	};

	return (
		<div>
			<Modal
				open={showModal.fiscalCompliance}
				onClose={() => setShowModal({ fiscalCompliance: false, annualArticle: false })}
				sx='!p-2'
			>
				<div className='!w-[90vw] max-w-[800px] h-[calc(100vh-100px)] pt-6 relative rounded-lg overflow-hidden'>
					<iframe
						title='Embedded Page'
						width='100%'
						height='100%'
						src='https://noticias.heru.app/en/articles/4287105'
						allowFullScreen
					></iframe>
				</div>
			</Modal>
			<Modal
				open={showModal.annualArticle}
				onClose={() => setShowModal({ fiscalCompliance: false, annualArticle: false })}
			>
				<div className='!w-[90vw] max-w-[800px] h-[calc(100vh-100px)] pt-6 relative'>
					<iframe
						title='Embedded Page'
						width='100%'
						height='100%'
						src='https://noticias.heru.app/en/articles/4295297'
						allowFullScreen
					></iframe>
				</div>
			</Modal>
			<PromotionalBanner />
			<section className='grid grid-cols-12 gap-6'>
				<div className='col-span-12 lg:col-span-6'>
					<div className='hidden lg:block mb-4 border-2 border-white shadow rounded-[16px] overflow-hidden'>
						{renderBanners()}
					</div>
					<FiscalProfileCard sx='shadow-sm' />

					<div className='flex flex-col px-6 pt-6 pb-4 bg-white rounded-lg relative my-6'>
						<FiscalDocShare
							fileName='Tu constancia de situación fiscal'
							status={fiscalSituationStatus}
							statusText={taxPayer?.status?.status ?? 'No disponible'}
							sx='mb-4'
							hrefFile={taxPayer?.status?.file?.file_url ?? ''}
						/>
						<FiscalDocShare
							status={complianceOpinion}
							fileName='Tu opinión de cumplimiento'
							hrefFile={taxPayer?.compliance?.file?.file_url ?? ''}
							statusText={compliceOpinionLabel}
						/>
					</div>

					<div className='block lg:hidden mb-6'>
						<MyFiscalStatus
							fiscalStatus={fiscalStatus}
							hideMonthlyPeriod={
								!!taxPayer?.status?.tax_regimes?.find(
									(r) => r.code !== REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS,
								)
							}
						/>
					</div>
					<InvoicesSumary
						title='Ultimas facturas'
						invoices={invoices}
					/>
				</div>
				<div className='col-span-12 lg:col-span-6 order-first lg:order-last'>
					<div className='block mb-4 lg:hidden border-2 border-white shadow rounded-[16px] overflow-hidden'>
						{renderBanners()}
					</div>

					<LastPendingOrder />

					{isLoadingDeclarationStatus ? (
						<LoadingComponent />
					) : (
						<>
							{showLucyView && declarationStatus && (
								<DeclarationStatusFFT declarationStatus={declarationStatus} />
							)}
							{!showLucyView && (
								<>
									{serviceStatusSubscription ? (
										<BlockedMock failedStripeSub={serviceStatusSubscription} />
									) : (
										<>{activeServices.length > 0 && <ServicesStatuHome />}</>
									)}
								</>
							)}
						</>
					)}

					<div className='hidden lg:block'>
						<MyFiscalStatus
							fiscalStatus={fiscalStatus}
							hideMonthlyPeriod={
								!!taxPayer?.status?.tax_regimes?.find(
									(r) => r.code !== REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS,
								)
							}
						/>
					</div>
				</div>
			</section>
		</div>
	);
};

export default React.memo(UserCredentialsLinked);

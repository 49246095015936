import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../shared/constants/routes';
import AnnualCountdown from '../../plansAndPricesV2/molecules/AnnualCountdown';

const PromotionalBanner = () => {
	const navigate = useNavigate();

	return (
		<>
			<button
				onClick={() => navigate(`${ROUTES.PLANS_AND_PRICES}?flow=annual`)}
				className='w-full'
			>
				<AnnualCountdown page= 'home' />
			</button>
		</>
	);
};

export default PromotionalBanner;

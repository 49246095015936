import React from 'react';
import { Files } from '@phosphor-icons/react';
import Text, { TTextColor } from '../../../../components/atoms/Text';
import PrimaryButton from '../../../../components/atoms/Button';
import { AdditionalInfo, FftItem } from '../../../../@types/FFT.type';
import StatusIcon from './StatusIcon';
interface CardProps {
	item: FftItem;
	monthName: string;
	year: string;
	handleDownload: (file: string) => void;
}

const StatusAndIcon: React.FC<{
	item: CardProps['item'];
	getIcon: (period: string) => JSX.Element;
}> = ({ item, getIcon }) => {
	const getStatusColor = (item: FftItem) => {
		if (item.is_manual && item.status !== 'completed') {
			return 'red';
		}
		switch (item.status) {
			case 'completed':
				return 'success';
			case 'pending':
				return 'gray';
			case 'missing':
				return 'gray';
			case 'in_progress':
			case 'closed':
				return 'primary';
			default:
				return 'dark';
		}
	};

	const statusColor = getStatusColor(item);

	const getStatusText = () => {
		if (item.is_manual && item.status !== 'completed') {
			return 'En progreso';
		}
		switch (item?.status) {
			case 'completed':
				return 'Finalizado';
			case 'pending':
				return 'Pendiente';
			case 'missing':
				return 'Comprar';
			case 'in_progress':
			case 'closed':
				return 'En progreso';
			default:
				return '';
		}
	};

	return (
		<div className='hidden lg:inline-flex self-stretch flex-col justify-center items-center gap-2'>
			<Text
				size='mini-1'
				weight='regular'
				sx='whitespace-nowrap'
				color={statusColor as TTextColor}
			>
				{getStatusText()}
			</Text>
			{getIcon(item?.period)}
		</div>
	);
};

const ServiceInfo: React.FC<{
	item: CardProps['item'];
	monthName: string;
	year: string;
}> = ({ item, monthName, year }) => (
	<div className='flex flex-col items-start'>
		<Text
			size='caption'
			weight='medium'
			sx='!text-[#080d30]'
		>
			{item?.service}
		</Text>
		<Text
			size='mini-1'
			weight={item?.status === 'completed' ? 'regular' : 'light'}
			sx={`${item?.status === 'completed' ? '!text-[#080d30] mb-1' : '!text-[#080d30]'}`}
		>
			{monthName} {year}
		</Text>
	</div>
);

const AdditionalContent: React.FC<{
	item: CardProps['item'];
	monthName: string;
	year: string;
	getIcon: (period: string) => JSX.Element;
}> = ({ item, monthName, year, getIcon }) => (
	<div className='grow shrink basis-0 h-auto flex justify-start items-center gap-2.5'>
		<div className='grow shrink basis-0 flex flex-col justify-center items-start gap-1'>
			<div className='self-stretch flex justify-between items-center lg:hidden'>
				<div className='flex items-center gap-2.5 flex-grow'>
					{getIcon(item?.period)}
					<Text
						size='caption'
						weight='regular'
						color='medium'
						sx='line-clamp-2 overflow-hidden'
					>
						{item?.service}
					</Text>
				</div>
				<Text
					size='mini-1'
					weight='light'
					color='darkBlue'
					sx='whitespace-nowrap ml-2 shrink-0'
				>
					{monthName.slice(0, 3)} {year}
				</Text>
			</div>
		</div>
	</div>
);

const StatusMessage: React.FC<{
	item: FftItem;
}> = ({ item }) => (
	<div
		className={`rounded ${
			item.is_manual && item.status !== 'completed'
				? 'border-[#da1e28] border-2 bg-[#fcf5f4] p-2'
				: ''
		}`}
	>
		<Text
			color={item.is_manual && item.status !== 'completed' ? 'red' : 'medium'}
			size='mini-1'
			weight='light'
		>
			{item.message?.description}
		</Text>
	</div>
);

const DownloadButtons: React.FC<{
	item: CardProps['item'];
	handleDownload: CardProps['handleDownload'];
}> = ({ item, handleDownload }) => {
	if (item?.status !== 'completed') return null;

	if ('additional_information' in item) {
		const { paid, detail, normal } =
			item.additional_information as AdditionalInfo;

		return (
			<div className='self-stretch flex justify-start items-center gap-2.5'>
				{paid && detail ? (
					<div className='flex flex-col gap-2 w-full mt-2'>
						<PrimaryButton
							variant='secondary'
							label='Acuse de pago'
							onClick={() => handleDownload(paid)}
							sx='w-full px-4'
							beforeIcon={
								<Files
									size={16}
									color='#1D8FF3'
								/>
							}
						/>
						<PrimaryButton
							variant='outline'
							label='Acuse detallado'
							onClick={() => handleDownload(detail)}
							sx='w-full px-4'
							beforeIcon={
								<Files
									size={16}
									color='#1D8FF3'
								/>
							}
						/>
					</div>
				) : normal ? (
					<PrimaryButton
						variant='secondary'
						label='Acuse de declaración'
						onClick={() => handleDownload(normal)}
						sx='w-full px-4 mt-2 !p-[8px]'
						labelSize='xs'
						beforeIcon={
							<Files
								size={16}
								color='#1D8FF3'
							/>
						}
					/>
				) : null}
			</div>
		);
	}

	return null;
};

const CardFft: React.FC<CardProps> = ({
	item,
	monthName,
	year,
	handleDownload,
}) => {
	const shouldMoveServiceInfo = [
		'pending',
		'missing',
		'in_progress',
		'closed',
	].includes(item?.status || '');

	const getIcon = () => {
		if (item.is_manual && item.status !== 'completed') {
			return <StatusIcon status='blocked' />;
		}
		switch (item?.status) {
			case 'completed':
				return <StatusIcon status='completed' />;
			case 'pending':
				return <StatusIcon status='pending' />;
			case 'in_progress':
				return <StatusIcon status='in_progress' />;
			case 'closed':
				return <StatusIcon status='closed' />;
			default:
				return <></>;
		}
	};

	const getTextColor = (item: FftItem) => {
		if (item.is_manual && item.status !== 'completed') {
			return 'text-h-red-50';
		} else if (item.message?.status === 'completed') {
			return 'text-h-success-50';
		} else if (
			item.message?.status === 'in_progress' ||
			item.message?.status === 'closed'
		) {
			return 'text-h-information-50';
		}
		return 'text-h-text-medium';
	};

	return (
		<div className='w-full h-full flex items-stretch'>
			<div
				className={`w-full p-2 lg:px-4 rounded-lg border  flex flex-col justify-between 
					lg:border-[#e6e6e6] lg:bg-white
					${item.is_manual && item.status !== 'completed' ? 'border-[#da1e28] bg-[#fcf5f4]' : ''}`}
			>
				<div>
					{shouldMoveServiceInfo && (
						<div className='mb-2 hidden lg:block'>
							<ServiceInfo
								item={item}
								monthName={monthName}
								year={year}
							/>
						</div>
					)}
					<div className='flex items-center gap-2.5'>
						<StatusAndIcon
							item={item}
							getIcon={getIcon}
						/>
						<div className='flex-col hidden lg:flex flex-grow'>
							{!shouldMoveServiceInfo && (
								<ServiceInfo
									item={item}
									monthName={monthName}
									year={year}
								/>
							)}
							<StatusMessage
								item={item}
							/>
						</div>
						<AdditionalContent
							item={item}
							monthName={monthName}
							year={year}
							getIcon={getIcon}
						/>
					</div>
					<div className='flex lg:hidden items-center gap-3 mt-2'>
						<span className='font-light text-sm'>
							<span
								className={`text-h-primary-40 ${getTextColor(item)} font-medium`}
							>
								{item?.status === 'completed'
									? 'Finalizado: '
									: item?.status === 'pending'
										? 'Pendiente: '
										: 'En progreso: '}
							</span>
							<span
								className={`${item.is_manual && item.status !== 'completed' ? 'text-h-red-50' : ''}`}
							>
								{` ${item?.message?.description}  `}
							</span>
						</span>
					</div>
				</div>
				{item?.status === 'completed' ? (
					<DownloadButtons
						item={item}
						handleDownload={handleDownload}
					/>
				) : (
					<div className='mt-auto'></div>
				)}
			</div>
		</div>
	);
};

export default CardFft;

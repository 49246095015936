import React from 'react';
import Text, { TTextSize } from '../../../components/atoms/Text';

interface Props {
	variant:
		| 'processing'
		| 'success'
		| 'error'
		| 'warning'
		| 'info'
		| 'blocked'
		| 'description'
		| 'pending'
		| 'downloading';
	title: string;
	description?: React.ReactNode;
	icon?: React.ReactNode;
	sx?: string;
	sizeTitle?: TTextSize;
	sizeDescription?: TTextSize;
}

const AlertBanner: React.FC<Props> = ({
	variant = 'processing',
	title,
	description,
	icon,
	sx,
	sizeTitle = 'caption',
	sizeDescription = 'caption',
}) => {
	const variantStyles: Record<string, string> = {
		success: 'bg-h-success-90 border-h-success-50',
		error: 'bg-h-error-90 border-h-error-50',
		warning: 'bg-h-warning-90 border-h-warning-50',
		info: 'bg-h-information-90 border-h-information-50',
		processing: 'bg-h-neutral-90 border-h-neutral-80 ',
		downloading: 'bg-h-primary-90 border-h-primary-40',
		blocked: 'bg-h-error-90 border-transparent',
		description: 'bg-h-primary-90 border-h-information-50',
		pending: 'bg-h-neutral-94',
	};

	const variantTextStyles: Record<string, string> = {
		success: '!text-h-success-50',
		error: '!text-h-error-30',
		warning: '!text-h-warning-30',
		info: '!text-h-information-30',
		processing: '!text-h-text-medium',
		downloading: '!text-h-primary-40',
		blocked: '!text-h-error-50',
		description: '!text-h-information-50',
		pending: '!text-h-text-medium',
	};

	return (
		<div
			className={`${variantStyles[variant]} border-t-4 p-2 sm:p-3 rounded-lg flex gap-2 ${sx}`}
		>
			<div className="flex-shrink-0 w-5 h-5 sm:w-6 sm:h-6">
				{icon}
			</div>
			<div>
				{!!title && (
					<Text
						size={sizeTitle}
						weight='medium'
						sx={variantTextStyles[variant]}
					>
						{title}
					</Text>
				)}
				{description && (
					<Text
						size={sizeDescription}
						weight='light'
						sx={`mt-1 ${variantTextStyles[variant]}`}
					>
						{description}
					</Text>
				)}
			</div>
		</div>
	);
};

export default AlertBanner;

import React from 'react';
import { CheckFat, ClockCountdown, Lock } from '@phosphor-icons/react';

interface StatusIconProps {
	status: 'completed' | 'in_progress' | 'pending' | 'blocked' | 'failed' | 'closed';
}

const StatusIcon: React.FC<StatusIconProps> = ({ status }) => {
	const getIconContent = () => {
		switch (status) {
			case 'completed':
				return (
					<CheckFat
						size={16}
						weight='fill'
						color='white'
					/>
				);
			case 'in_progress':
				return (
					<ClockCountdown
						size={16}
						weight='fill'
						color='white'
					/>
				);
			case 'pending':
			case 'blocked':
			case 'failed':
			case 'closed':
				return (
					<Lock
						size={16}
						weight='fill'
						color='white'
					/>
				);
		}
	};

	const getBgColor = () => {
		switch (status) {
			case 'completed':
				return 'bg-[#6aa132]';
			case 'in_progress':
				return 'bg-[#2F85C6]';
			case 'pending':
			case 'closed':
				return 'bg-h-neutral-80';
			case 'blocked':
			case 'failed':
				return 'bg-[#DA1E28]';
		}
	};

	return (
		<div className={`${getBgColor()} w-7 h-7 min-w-[1.75rem] min-h-[1.75rem] rounded-full flex items-center justify-center p-[2px]`}>
			<div className='w-full h-full rounded-full bg-white flex items-center justify-center p-[2px]'>
				<div className={`${getBgColor()} w-full h-full rounded-full flex items-center justify-center`}>
					{getIconContent()}
				</div>
			</div>
		</div>
	);
};

export default StatusIcon;

import { Fragment, useMemo } from 'react';

import Text from '../../atoms/Text';
import {
	getCompleteMonth,
	getPrefixMonth,
} from '../../../shared/utils/dates.util';
import {
	Message,
	Steps,
} from '../../../@types/FFT.type';
import {
	CalendarBlank,
	CheckFat,
	ClockCountdown,
	ShoppingCart,
	WarningCircle,
	Lock,
	ListMagnifyingGlass,
	SpinnerGap,
	Files,
} from '@phosphor-icons/react';
import { DATES } from '../../../shared/utils/dates';
import AlertBanner from '../../molecules/AlertBanner';
import { useMediaQuery } from '@mui/material';
import { FftItem } from '../../../@types/FFT.type';

interface CardFFTProps {
	declaration: FftItem;
}

const steps = [
	{
		label: 'Inicio',
		slug: 'start',
	},
	{
		label: 'En progreso',
		slug: 'in-progress',
	},
	{
		label: 'Finalizado',
		slug: 'finished',
	},
];

const CardDetailFFT = ({ declaration }: CardFFTProps) => {
	const isMobile = useMediaQuery('(max-width: 768px)');
	const isCurrentDeclaration = useMemo(
		() => (declaration: FftItem) => {
			if (!declaration.period) return false;
			if (
				declaration.service.includes('Anual') ||
				declaration.service.includes('ASAT')
			)
				return false;
			const year = parseInt(declaration.period.split('-')[0]);
			const month = parseInt(declaration.period.split('-')[1]);
			const currentYear =
				DATES.currentMonth === 1 ? DATES.currentYear - 1 : DATES.currentYear;
			const currentMonth =
				DATES.currentMonth === 1 ? 12 : DATES.currentMonth - 1;
			if (year === currentYear && month === currentMonth) {
				return true;
			}
			return false;
		},
		[],
	);

	const getPeriod = (period: string, service: string) => {
		if (service.includes('Anual')) {
			const year = period.split('-')[0];
			return `${year}`;
		}
		const year = period.split('-')[0];
		const month = parseInt(period.split('-')[1]);
		return `${isMobile ? getPrefixMonth(month) : getCompleteMonth(month)} ${year}`;
	};

	const getTextColor = (step: string, item: FftItem) => {
		if (step === 'start' && item.steps.init === 'completed') {
			return 'greenSuccess';
		}

		if (step === 'in-progress') {
			if (item.steps.inProgress === 'completed') {
				return 'greenSuccess';
			}
			if (item.is_manual && item.status !== 'completed') {
				return 'red';
			}
			if (item.steps.inProgress === 'in_progress') {
				return 'blueInformation';
			}
		}

		if (step === 'finished') {
			if (item.steps.completed === 'completed') {
				return 'greenSuccess';
			}
		}

		return 'medium';
	};

	const getColorStepLine = (status: string, steps: Steps) => {
		if (status === 'start' && steps.init === 'completed') {
			return 'bg-h-success-50';
		}

		if (steps.completed === 'completed') {
			return 'bg-h-success-50';
		}

		return 'bg-h-neutral-80';
	};

	const getColorStep = (status: string, item: FftItem) => {
		if (item.steps.completed === 'completed') {
			return 'bg-h-success-50';
		}

		if (status === 'start') {
			if (item.steps.init === 'completed') {
				return 'bg-h-success-50';
			}
			if (
				item.is_manual &&
				item.status !== 'completed' &&
				item.steps.init === 'in_progress'
			) {
				return 'bg-h-error-50';
			}

			if (item.steps.init === 'in_progress') {
				return 'bg-h-information-50';
			}
		}

		if (status === 'in-progress') {
			if (item.steps.inProgress === 'completed') {
				return 'bg-h-success-50';
			}
			if (item.steps.inProgress === 'in_progress') {
				if (item.is_manual && item.status !== 'completed') {
					return 'bg-h-error-50';
				}
				if (item.message.status === 'in_progress') {
					return 'bg-h-information-50';
				}
			}
		}

		return 'bg-h-neutral-80';
	};

	const getIcon = (step: string, steps: Steps, message: Message) => {
		if (steps.completed === 'completed') {
			return (
				<CheckFat
					size={16}
					weight='fill'
					color='white'
				/>
			);
		}
		if (step === 'start') {
			if (steps.init === 'completed') {
				return (
					<CheckFat
						size={16}
						weight='fill'
						color='white'
					/>
				);
			}

			if (steps.init === 'in_progress') {
				return (
					<ClockCountdown
						size={16}
						weight='fill'
						color='white'
					/>
				);
			}
		}

		if (step === 'in-progress') {
			if (steps.inProgress === 'completed') {
				return (
					<CheckFat
						size={16}
						weight='fill'
						color='white'
					/>
				);
			}

			if (steps.inProgress === 'in_progress') {
				if (message.status === 'in_progress') {
					return (
						<ClockCountdown
							size={16}
							weight='fill'
							color='white'
						/>
					);
				}
				if (message.status === 'blocked' || message.status === 'failed') {
					return (
						<Lock
							size={16}
							weight='fill'
							color='white'
						/>
					);
				}
			}
		}

		return <></>;
	};

	const getColorAlertBanner = (item: FftItem) => {
		if (item.is_manual && item.status !== 'completed') {
			return 'blocked';
		}
		if (item.message.status === 'in_progress') {
			return 'description';
		}
		if (item.message.status === 'completed') {
			return 'success';
		}
		return 'pending';
	};

	const getIconAlertBanner = (item: FftItem) => {
		if (item.is_manual && item.status !== 'completed') {
			return (
				<WarningCircle
					size={24}
					weight='fill'
					color='var(--h-error-50)'
				/>
			);
		}

		if (item.message.status === 'in_progress' || item.message.status === 'completed') {
			return (
				<ListMagnifyingGlass
					size={24}
					weight='fill'
					color={
						item.message.status === 'in_progress'
							? 'var(--h-information-50)'
							: 'var(--h-success-50)'
					}
				/>
			);
		}

		if (item.message.status === 'pending') {
			return (
				<SpinnerGap
					size={24}
					weight='bold'
					color='#4d4d4d'
				/>
			);
		}
		return <></>;
	};

	return (
		<div
			className={`flex flex-col p-6 rounded-lg shadow-md mt-6 gap-y-4 ${declaration.is_manual && declaration.status !== 'completed' ? 'border-[#da1e28] border-2 bg-[#fcf5f4]' : 'bg-white'}`}
		>
			<div className='flex items-center justify-between'>
				<Text
					size='caption'
					color='darkBlue'
					weight='regular'
				>
					{declaration.service}
				</Text>
				<Text
					size='mini-1'
					weight='light'
					color='medium'
				>
					{getPeriod(declaration.period, declaration.service)}
				</Text>
			</div>

			<div className='flex flex-col md:flex-row gap-y-2'>
				<div className='flex items-center gap-x-2 md:w-1/2'>
					<ShoppingCart
						size={20}
						color='var(--h-primary-50)'
					/>
					<div
						className='flex gap-x-2 md:flex-col
            '
					>
						<Text
							size='mini-1'
							weight='light'
							color='medium'
							sx='!text-[#49566E]'
						>
							Detalle de la compra:
						</Text>
						<Text
							size='mini-1'
							weight='light'
							color='medium'
							sx='!text-[#49566E]'
						>
							{`#${declaration?.id}`}
						</Text>
					</div>
				</div>

				{isCurrentDeclaration(declaration) && (
					<div className='flex items-center gap-x-2'>
						<CalendarBlank
							size={20}
							color='var(--h-primary-50)'
						/>
						<div
							className='flex gap-x-2 md:flex-col
            '
						>
							<Text
								size='mini-1'
								weight='light'
								color='medium'
								sx='!text-[#49566E]'
							>
								Fecha estimada de entrega:
							</Text>
							<Text
								size='mini-1'
								weight='light'
								color='medium'
								sx='!text-[#49566E]'
							>
								{`17 al 22 ${getPrefixMonth(DATES.currentMonth)} ${DATES.currentYear}`}
							</Text>
						</div>
					</div>
				)}
			</div>

			<div className='flex items-end pt-4'>
				{steps.map((step, index) => (
					<Fragment key={`step-${index}`}>
						<div className='flex flex-col items-center justify-center w-[70px]'>
							<Text
								size='mini-1'
								color={getTextColor(step.slug, declaration)}
								sx='!block text-center mb-1 w-full whitespace-nowrap'
							>
								{step.label}
							</Text>
							<div
								className={`${getColorStep(step.slug, declaration)} size-8 rounded-full flex items-center justify-center`}
							>
								<div className='size-6 rounded-full bg-white flex items-center justify-center'>
									<div
										className={`${getColorStep(step.slug, declaration)} size-5 rounded-full flex items-center justify-center`}
									>
										{getIcon(step.slug, declaration.steps, declaration.message)}
									</div>
								</div>
							</div>
						</div>
						{step.slug !== 'finished' && (
							<div
								className={`${getColorStepLine(step.slug, declaration.steps)} h-[2px] flex-1 rounded-lg mb-4`}
							></div>
						)}
					</Fragment>
				))}
			</div>

			<AlertBanner
				icon={getIconAlertBanner(declaration)}
				title={declaration.message?.title ?? ''}
				variant={getColorAlertBanner(declaration)}
				description={declaration.message?.description ?? ''}
				sx='!border-t-0 shadow-lg'
			/>
			{declaration?.additional_information?.normal ? (
				<div className='flex justify-end'>
					<button
						className='flex w-full py-3 gap-2 bg-h-primary-90 items-center justify-center px-8 rounded-lg'
						type='button'
						onClick={() => {
							window.open(
								declaration?.additional_information?.normal,
								'_blank',
							);
						}}
					>
						<Files
							size={24}
							color='var(--h-primary-30)'
						/>
						<Text
							size='caption'
							sx='!text-h-primary-30'
						>
							Acuse de declaración
						</Text>
					</button>
				</div>
			) : declaration?.additional_information?.detail && declaration?.additional_information?.paid && (				
				<div className='flex flex-col gap-4 lg:flex-row lg:gap-4'>
					<button
						className='flex w-full py-3 gap-2 bg-h-primary-90 items-center justify-center px-8 rounded-lg'
						type='button'
						onClick={() => {
							window.open(
								declaration?.additional_information?.paid,
								'_blank',
							);
						}}
					>
						<Files
							size={24}
							color='var(--h-primary-30)'
						/>
						<Text
							size='caption'
							sx='!text-h-primary-30'
						>
							Acuse de declaración pagado
						</Text>
					</button>
					<button
						className='flex w-full py-3 gap-2 bg-h-primary-90 items-center justify-center px-8 rounded-lg'
						type='button'
						onClick={() => {
							window.open(
								declaration?.additional_information?.detail,
								'_blank',
							);
						}}
					>
						<Files
							size={24}
							color='var(--h-primary-30)'
						/>
						<Text
							size='caption'
							sx='!text-h-primary-30'
						>
							Detalle de declaración
						</Text>
					</button>
				</div>
			)}
		</div>
	);
};

export default CardDetailFFT;

import { useMediaQuery } from '@mui/material';
import Text from '../../../components/atoms/Text';
import Heading from '../../../components/atoms/Heading';

const PlanOverview = ({
	title,
	description,
}: {
	title: string;
	description: React.ReactNode;
}) => {
	const isMobile = useMediaQuery('(max-width: 768px)');

	return (
		<div className='mb-2 sm:mb-8 !text-left '>
			{isMobile ? (
				<Text
					size='body-3'
					weight='bold'
					sx='px-3'
				>
					{title}
				</Text>
			) : (
				<Heading
					children={title}
					weight='bold'
					size='xs'
					sx='mt-3 px-3'
				/>
			)}
			<Text
				size={isMobile ? 'mini-1' : 'body-2'}
				weight='light'
				sx='px-3 !block mt-1'
			>
				{description}
			</Text>
		</div>
	);
};

export default PlanOverview;

import { REGIMES_CODE, REGIMES_NAME } from '../../../@types/Regime.type';
import { PROMO_PRICES } from '../../../shared/constants/general';

export const subscriptionsProductsDefaults = [
	{
		id: 'heru-plus',
		order: 1,
		name: 'Heru Plus',
		showTag: true,
		tagText: 'Más comprado',
		desc: 'Tu contabilidad mensual 100% cubierta para todo tipo de ingreso',
		showPrice: true,
		price: 499,
		beforePriceText: '',
		afterPriceText: 'mensual',
		principalBenefits: [
			'Cubre todas tus actividades económicas como persona física',
			'Identificamos y asociamos automáticamente todos tus ingresos y gastos',
			'Analizamos todos tus gastos con inteligencia artificial en busca de deducibles',
		],
		allBenefits: [
			'Cubre todas tus actividades económicas como persona física',
			'Identificamos y asociamos automáticamente todos tus ingresos y gastos',
			'Analizamos todos tus gastos con inteligencia artificial en busca de deducibles',
			'Solo vincula, no te solicitaremos ningún documento',
			'Seguimiento de todas tus declaraciones desde la app',
			'Acceso ilimitado al tablero de impuestos',
			'Actualización de tus CFDIs automática cada 48 horas',
			'Visualiza tus CFDIs y acuses de hasta 5 años atrás',
			'Genera 5 faturas gratis al mes a través de nuestro servicio de facturación',
			'Soporte especializado por WhatsApp o correo',
			'Asesoría contable',
		],
		principalButton: 'Contratar',
		showCTALink: true,
		CTALinkText: '¿Cómo funciona?',
		CTALinkUrl: 'https://www.youtube.com/shorts/L4_i_GrMwt0',
		beforePrice: '',
		newPriceText: undefined,
	},
	{
		id: 'basic-accounting',
		order: 2,
		name: 'Contabilidad básica',
		showTag: false,
		tagText: '',
		desc: 'Tu contabilidad mensual 100% cubierta para una sola fuente de ingreso',
		showPrice: false,
		price: 199,
		beforePriceText: 'Desde',
		afterPriceText: 'Mensual',
		principalBenefits: [
			'Cubre una única fuente de ingreso',
			'Identificamos y asociamos automáticamente todos tus ingresos y gastos',
			'Analizamos todos tus gastos con inteligencia artificial en busca de deducibles',
		],
		allBenefits: [
			'Cubre una única fuente de ingreso',
			'Identificamos y asociamos automáticamente todos tus ingresos y gastos',
			'Analizamos todos tus gastos con inteligencia artificial en busca de deducibles',
			'Solo vincula, no te solicitaremos ningún documento',
			'Evitamos que pagues multas y recargos',
		],
		principalButton: 'Contratar',
		showCTALink: true,
		CTALinkText: '¿Cómo funciona?',
		CTALinkUrl: 'https://www.youtube.com/shorts/L4_i_GrMwt0',
		beforePrice: '',
		newPriceText: undefined,
	},
];

export const annualProductsStatic = [
	{
		id: 'annual',
		order: 1,
		name: 'Declaración anual 2024',
		showTag: false,
		tagText: '',
		desc: '¡Tu declaración anual estará completamente cubierta, considerando todos tus ingresos!',
		showPrice: false,
		price: PROMO_PRICES.ANNUAL_BACKLOG_REGULAR_PRICE,
		beforePriceText: 'Desde',
		afterPriceText: '',
		principalBenefits: [
			'Presentaremos tu declaración en Abril del año 2025.',
			'Suscríbete y deja que nos ocupemos de esta responsabilidad por ti.',
		],
		allBenefits: [
			'Presentaremos tu declaración en Abril del año 2025.',
			'Suscríbete y deja que nos ocupemos de esta responsabilidad por ti.',
			'Evaluamos más de 50 mil productos deducibles para favorecer tus saldos de impuestos.',
			'Hacemos el seguimiento correspondiente a tu devolución automática de saldo a favor.',
			'Resolvemos todas tus dudas sobre Declaración Anual.',
			'Consulta y descarga tus acuses de declaración en la app.',
			'En caso de que detectemos que tienes meses pendientes por declarar, te contactaremos para asesorarte.',
		],
		principalButton: 'Contratar',
		showCTALink: false,
		CTALinkText: '',
		CTALinkUrl: '',
		beforePrice: undefined,
		newPriceText: undefined,
	},
	{
		id: 'annual-declarations-default',
		order: 1,
		name: 'Declaraciones anuales atrasadas',
		showTag: false,
		tagText: '',
		desc: '¡Presentamos tu declaración anual hasta 4 años atrás!',
		showPrice: false,
		price: PROMO_PRICES.ANNUAL_BACKLOG_REGULARIZATION_PRICE,
		beforePriceText: '',
		afterPriceText: '',
		principalBenefits: [
			'Evaluamos más de 50 mil productos deducibles para favorecer tus saldos de impuestos.',
			'Hacemos el seguimiento correspondiente a tu devolución automática de saldo a favor.',
			'Resolvemos todas tus dudas sobre Declaración Anual.',
		],
		allBenefits: [
			'Evaluamos más de 50 mil productos deducibles para favorecer tus saldos de impuestos.',
			'Hacemos el seguimiento correspondiente a tu devolución automática de saldo a favor.',
			'Resolvemos todas tus dudas sobre Declaración Anual.',
			'Consulta y descarga tus acuses de declaración en la app.',
			'En caso de que detectemos que tienes meses pendientes por declarar, te contactaremos para asesorarte.',
		],
		principalButton: 'Contratar',
		showCTALink: false,
		CTALinkText: '',
		CTALinkUrl: '',
		beforePrice: undefined,
		newPriceText: undefined,
	},
];

export const asatDefaultProducts = [
	{
		id: 'asat-plus',
		order: 1,
		name: 'Plus',
		showTag: false,
		tagText: '',
		desc: 'Servicio de Tramites con el SAT',
		showPrice: false,
		price: 500,
		beforePriceText: '',
		afterPriceText: '',
		principalBenefits: [
			'Sacamos tu cita con el SAT.',
			'Te damos de alta en el régimen que necesites.',
			'Obtención de sellos digitales.',
		],
		allBenefits: [
			'Sacamos tu cita con el SAT.',
			'Te damos de alta en el régimen que necesites.',
			'Obtención de sellos digitales.',
			'Cambiamos tu domicilio.',
			'Baja o modificación de obligaciones.',
			'Asesoría personalizada con un contador asignado.',
		],
		principalButton: 'Agregar al carrito',
		showCTALink: false,
		CTALinkText: '',
		CTALinkUrl: '',
		beforePrice: '',
		newPriceText: undefined,
	},
	// {
	// 	id: 'asat-basic',
	// 	order: 1,
	// 	name: 'Basic',
	// 	showTag: false,
	// 	tagText: '',
	// 	desc: 'Servicio de Tramites con el SAT',
	// 	showPrice: false,
	// 	price: 100,
	// 	beforePriceText: '',
	// 	afterPriceText: '',
	// 	principalBenefits: [
	// 		'Actualizamos tu domicilio fiscal.',
	// 		'Te damos de alta en el régimen que necesites.',
	// 		'Te proporcionamos todos tus documentos fiscales importantes.',
	// 	],
	// 	allBenefits: [
	// 		'Actualizamos tu domicilio fiscal.',
	// 		'Te damos de alta en el régimen que necesites.',
	// 		'Te proporcionamos todos tus documentos fiscales importantes.',
	// 	],
	// 	principalButton: 'Agregar al carrito',
	// 	showCTALink: false,
	// 	CTALinkText: '',
	// 	CTALinkUrl: '',
	// 	beforePrice: '',
	// 	newPriceText: undefined,
	// },
];

export const regularizationProductsDefaults = [
	{
		id: 'regularizations-default',
		order: 1,
		name: 'Declaraciones Atrasadas',
		showTag: false,
		tagText: '',
		desc: 'Procesamos tus declaraciones atrasadas de cualquier actividad',
		showPrice: false,
		price: 299,
		beforePriceText: 'Desde',
		afterPriceText: '',
		principalBenefits: [
			'Evitamos que tus multas y recargos se incrementen',
			'Con la ayuda de nuestra inteligencia artificial, buscamos que pagues menos impuestos',
			'Identificamos y asociamos automáticamente todos tus ingresos y gastos',
		],
		allBenefits: [
			'Evitamos que tus multas y recargos se incrementen',
			'Con la ayuda de nuestra inteligencia artificial, buscamos que pagues menos impuestos',
			'Identificamos y asociamos automáticamente todos tus ingresos y gastos',
			'Verifica tu declaración con un contador',
		],
		principalButton: 'Contratar',
		showCTALink: false,
		CTALinkText: '',
		CTALinkUrl: '',
		beforePrice: '',
		newPriceText: undefined,
	},
];

export const PRODUCT_TYPE_PYP2 = {
	HERU_PLUS: 'heru-plus',
	ASAT: 'asat',
	RESICO: 'resico',
	RIF: 'rif',
	ACTIVIDAD_EMPRESARIAL: 'actividad-empresarial',
	ARRIENDOS: 'arriendos',
	PLATAFORMAS_TECNOLOGICAS: 'plataformas-tecnologicas',
	PT_ARRIENDOS: 'pt-arriendos',
	SINGLE_PT: 'single-pt',
};

export const ARRIENDOS_PYP2_OPTIONS = [
	{
		id: 1,
		slug: 'decl_arr_mes_1',
		label: 'Realizo declaraciones mensuales',
		desc1: 'Ingresos mensuales mayores a 27,000',
	},
	{
		id: 2,
		slug: 'decl_arr_tri_3',
		label: 'Realizo declaraciones trimestrales',
		desc1: 'Ingresos mensuales menores a 27,000',
	},
];

export const PYP_CATEGORY_LABELS = [
	'Planes de contabilidad',
	'Declaraciones Atrasadas',
	'Declaraciones Anuales',
	'Tramites con el SAT',
];

export const PYP_CATEGORY_LABELS_MOBILE = [
	'Planes de contabilidad',
	'Declaraciones Atrasadas',
	'Declaraciones Anuales',
	'Tramites con el SAT',
	'Todos los planes',
];

export const regimesList = [
	{
		id: 'act_emp',
		name: 'Actividades Empresariales',
		desc: 'Soy independiente o tengo un pequeño negocio',
	},
	{
		id: 'resico',
		name: 'RESICO',
		desc: 'Ingresos menores a 3.5 mdp/año y sin salario',
	},
	{
		id: 'pt',
		name: 'Plataformas tecnológicas',
		desc: 'Ganas dinero por aplicaciones o internet',
	},
	{
		id: 'arriendos',
		name: 'Arrendamiento',
		desc: 'Tus ingresos son de rentas de propiedades',
	},
	{
		id: 'rif',
		name: 'RIF',
		desc: 'Eres emprendedor',
	},
];

export const AnnualRegimesList = [
	{
		id: 'pt',
		name: 'Plataformas tecnológicas',
		desc: 'Ganas dinero por aplicaciones o internet',
	},
	{
		id: 'sys',
		name: 'Sueldos y salarios',
		desc: 'Tienes un patrón que paga tu salario',
	},
	{
		id: 'arriendos',
		name: 'Arrendamiento',
		desc: 'Tus ingresos son de rentas de propiedades',
	},
	{
		id: 'resico',
		name: 'RESICO',
		desc: 'Ingresos menores a 3.5 mdp/año y sin salario',
	},
	{
		id: 'act_emp',
		name: 'Actividades Empresariales',
		desc: 'Soy independiente o tengo un pequeño negocio',
	},
];

export const annualBenefits = [
	'Evaluamos más de 50 mil productos deducibles para favorecer tus saldos de impuestos.',
	'Hacemos el seguimiento correspondiente a tu devolución automática de saldo a favor.',
	'Resolvemos todas tus dudas sobre Declaración Anual.',
	'Consulta y descarga tus acuses de declaración en la app.',
];

export const basicAccountingBenefits = [
	'Cubre todas tus actividades económicas como persona física.',
	'Identificamos y asociamos automáticamente todos tus ingresos y gastos.',
	'Analizamos todos tus gastos con inteligencia artificial en busca de deducibles.',
	'Solo vincula, no te solicitaremos ningún documento.',
	'Evitamos que pagues multas y recargos.',
];

export const arrOptions = [
	{
		id: 'arr-monthly',
		name: 'Realizo declaraciones mensuales',
		desc: 'Ingresos mensuales mayores a $27.000',
	},
	{
		id: 'arr-quarterly',
		name: 'Realizo declaraciones trimestrales',
		desc: 'Ingresos mensuales menores a $27.000',
	},
];

export const regularizationBenefits = [
	'Cubre todas tus actividades económicas como persona física.',
	'Identificamos y asociamos automáticamente todos tus ingresos y gastos.',
	'Analizamos todos tus gastos con inteligencia artificial en busca de deducibles.',
	'Solo vincula, no te solicitaremos ningún documento.',
	'Seguimiento de todas tus declaraciones desde la app.',
	'Acceso ilimitado al tablero de impuestos.',
	'Actualización de tus CFDIs automática cada 48 horas.',
	'Visualiza tus CFDIs y acuses de hasta 5 años atrás.',
	'Genera 5 facturas gratis al mes a través de nuestro servicio de facturación.',
	'Soporte especializado por WhatsApp o correo.',
	'Asesoría contable.',
];

export const regularizationRegimes = [
	{
		id: 1,
		label: REGIMES_NAME.ACTIVIDAD_EMPRESARIAL,
		value: REGIMES_NAME.ACTIVIDAD_EMPRESARIAL,
		description: 'Soy independiente o tengo un pequeño negocio',
		code: REGIMES_CODE.ACTIVIDAD_EMPRESARIAL,
	},
	{
		id: 2,
		label: REGIMES_NAME.RESICO,
		value: REGIMES_NAME.RESICO,
		description: 'Ingresos menores a 3.5 mdp/año y sin salario',
		code: REGIMES_CODE.RESICO,
	},
	{
		id: 3,
		label: REGIMES_NAME.PLATAFORMAS_TECNOLOGICAS,
		value: REGIMES_NAME.PLATAFORMAS_TECNOLOGICAS,
		description: 'Ganas dinero por aplicaciones o internet',
		code: REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS,
	},
	{
		id: 4,
		label: REGIMES_NAME.ARRENDAMIENTO,
		value: REGIMES_NAME.ARRENDAMIENTO,
		description: 'Tus ingresos son de rentas de propiedades',
		code: REGIMES_CODE.ARRENDAMIENTO,
	},
	{
		id: 5,
		label: 'RIF',
		value: 'RIF',
		description: 'Eres emprendedor',
		code: REGIMES_CODE.RIF,
	},
];

export const rentalRevenues = [
	{
		id: 1,
		label: 'Obligatorio si ganas más de $27,000 al mes',
		value: 'GanaMás',
		description: 'Debes realizar declaraciones mensuales',
	},
	{
		id: 2,
		label: 'Ideal si ganas menos de $27,000 al mes',
		value: 'GanaMenos',
		description: 'Debes realizar declaraciones trimestrales',
	},
];

export enum PRODUCTS_BY_SLUG {
	HERU_PLUS = 'decl_full_1',
	ACTIVIDAD_EMPRESARIAL = 'decl_act_emp_1',
	RESICO = 'decl_resico_plus_1',
	ARR_MONTHLY = 'decl_arr_mes_1',
	ARR_QUARTERLY = 'decl_arr_tri_3',
	PT_ARRIENDOS = 'decl_pt_arr_1',
	PLATAFORMAS_TECNOLOGICAS = 'declaration',
	RIF = 'decl_rif_2',
	PLAN_ENTREPRENEUR = 'plan_entrepreneur',
	PLAN_PLATFORM = 'plan_platform',
	PLAN_BUSINESS = 'plan_business',
}

export enum CATEGORY_SINGLE {
	HERU_PLUS = 'heru-plus',
	BASIC_ACCOUNTING = 'basic-accounting',
	REGULARIZATION = 'regularization',
	ANNUAL = 'annual',
}

export enum PRODUCTS_PERIODICITIES_ID {
	HERU_PLUS_MONTHLY = 92,
	ACT_EMP_MONTHLY = 124,
	RESICO_MONTHLY = 78,
	PT_MONTHLY = 16,
	RIF_MONTHLY = 89,
	PT_ARR_MONTHLY = 96,
	ARR_MONTHLY = 82,
	ARR_QUARTERLY = 86,
}

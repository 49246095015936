import { useEffect, useState } from 'react';
import Text from '../../../components/atoms/Text';
import Tabs from '../../../components/molecules/Tabs';
import SATLinkingForm from '../../../components/molecules/SATLinkingForm';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { linkedAccount, startLoader } from '../../../redux/slices/sat.slice';
import { fetchSATLinkedById } from '../../../services/satLinking.service';
import { RootState } from '../../../redux/store';

import { Warning } from '@phosphor-icons/react';
import { SATLinkStatus } from '../../../@types/SATLinking';
import { ShoppingCartDetail } from '../../../@types/LastSchoppingCart';
import { getLastShoppingCartWithDetail } from '../../../services/shoppingCart.service';
import CustomIcon from '../../../components/atoms/CustomIcon';
import { getBrandIcon } from '../../../shared/utils/payments.util';
import LastPendingOrder from '../../pendingReferences/components/LastPendingOrder';
import PromotionalBanner from '../molecules/PromotionalBanner';
import Heading from '../../../components/atoms/Heading';
import { CircularProgress } from '@mui/material';

interface Props {
	page: string;
}

const items = [
	{
		label: 'Vincular con e.firma',
		slug: 'efirma',
	},
	{
		label: 'Vincular con RFC y contraseña',
		slug: 'password',
	},
];

const UnlinkedUser = ({ page }: Props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showLinkInfo, setShowLinkInfo] = useState(false);
	const [activeTab, setActiveTab] = useState(items[0].slug);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const { status } = useSelector((state: RootState) => state.satLinkStatus);
	const userProfile = useSelector((state: RootState) => state.profile.user);

	const [lastShoppingCart, setLastShoppingCart] =
		useState<ShoppingCartDetail>();

	const [isLoading, setIsLoading] = useState(false);

	const fetchSatLinkedAcount = async () => {
		try {
			dispatch(startLoader(true));
			const resource = await fetchSATLinkedById(tokenSession);
			dispatch(linkedAccount(resource));
		} catch (error: any) {
			dispatch(startLoader(false));
		}
	};

	const getLastShoppingCart = async () => {
		try {
			const response = await getLastShoppingCartWithDetail(tokenSession);
			setLastShoppingCart(response);
		} catch (error) {}
	};

	const purchaseTypeToIcon = (type: number) => {
		switch (type) {
			case 2:
				return 'oxxo';
			case 3:
				return 'spei';
			case 4:
				return 'paypal';
			default:
				return 'oxxo';
		}
	};

	const onLoadingChange = (loading: boolean) => {
		setIsLoading(loading);
	};

	useEffect(() => {
		if (status.status !== SATLinkStatus.DELETED && userProfile?.id) {
			getLastShoppingCart();
		}
	}, [userProfile]);

	return (
		<>
			{isLoading ? (
				<div className='w-full h-screen flex flex-col items-start justify-center gap-10 lg:pl-40 lg:pr-40 pl-4 pr-4'>
					<div className='flex justify-center items-center w-full'>
						<CircularProgress size={60} />
					</div>
					<Text
						size='body-1'
						weight='medium'
					>
						{userProfile?.first_name ? `¡${userProfile?.first_name}!` : ''}
					</Text>
					<Heading
						size='m'
						weight='bold'
					>
						Estamos verificando tu información para asegurar una vinculación
						exitosa.
					</Heading>
					<Heading
						size='s'
						weight='bold'
						color='blue'
					>
						{page === 'home'
							? 'En breve, todos tus servicios estarán activados.'
							: page === 'service-status'
								? 'Si detectamos algún error en tu régimen, te contactaremos. Si todo está en orden, iniciaremos tu servicio.'
								: page === 'fiscal-documents'
								? 'En breve, podrás visualizar todos tus documentos fiscales.'
								: page === 'taxes-and-invoices'
								? 'En breve, podrás consultar tus ingresos, retenciones y deducibles en el tablero.'
								: ''}
					</Heading>
				</div>
			) : (
				<div className='w-full max-w-[800px] mx-auto'>
					<PromotionalBanner />
					<div className='mb-4'>
						<LastPendingOrder isSingle={false} />
					</div>

					<div
						className={`${
							status.status === SATLinkStatus.DELETED
								? 'bg-[#f1d6d2]'
								: 'bg-[#FCE38E]'
						} p-4 rounded-lg flex items-center justify-center gap-2 ${
							status.status === SATLinkStatus.DELETED
								? '!text-[#da1e28]'
								: '!text-[#62470F]'
						}`}
					>
						<Warning
							size={24}
							weight='fill'
							className='min-w-6'
						/>
						<div>
							<Text
								sx={`${
									status.status === SATLinkStatus.DELETED
										? '!text-[#da1e28]'
										: '!text-[#62470F]'
								}`}
								size='caption'
								weight='medium'
							>
								{status.status === SATLinkStatus.DELETED
									? 'Desvinculaste tus credenciales con un servicio en curso.'
									: lastShoppingCart?.status === 'pending' &&
										  !lastShoppingCart?.token
										? 'Necesitamos que realices el pago del servicio adquirido para iniciar'
										: 'Necesitamos que vincules tus credenciales, para iniciar con tu servicio ¡Vinculate ahora!'}
							</Text>
							<Text
								size='caption'
								weight='light'
								sx={`!text-[#da1e28] ${
									status.status === SATLinkStatus.DELETED ? 'block' : 'hidden'
								}`}
							>
								Vincula nuevamente para continuar con tu servicio
							</Text>
						</div>
					</div>

					<div
						onClick={() => setShowLinkInfo(false)}
						className={`mt-6 rounded-lg border-4  border-h-primary shadow-md`}
					>
						{showLinkInfo ? (
							<div>
								<Text
									size='s'
									sx='mb-2 lg:text-xl bg-h-primary text-white p-4'
									weight='medium'
								>
									Vincula aquí tu RFC para calcular tus deducibles e iniciar con
									tu declaración anual
								</Text>
								<Text
									size='xs'
									sx='mb-8 bg-h-primary text-white px-4 pb-4'
									weight='light'
								>
									Para brindarte un mejor servicio, necesitamos acceder a tu
									información fiscal. Tus datos están seguros y solo serán
									utilizados para el cálculo de tus declaraciones.
								</Text>
							</div>
						) : (
							<div>
								<Text
									size='m'
									sx='text-center mt-6 mb-2 mx-4 lg:text-xl flex justify-center items-center'
									weight='medium'
								>
									Vincula tu cuenta fiscal con tu e.firma o RFC
								</Text>
								<Text
									size='s'
									sx='text-center mb-8 mx-4 flex justify-center items-center text-gray-600'
									weight='light'
								>
									El paso más importante para simplificar tus impuestos y lograr
									completa visibilidad de tus datos fiscales es vincular tu
									cuenta fiscal. Este proceso lo puedes hacer mediante tu
									e.firma o RFC.
								</Text>
							</div>
						)}

						<Tabs
							items={items}
							onClickTab={(val) => setActiveTab(val)}
						/>
						<SATLinkingForm
							onCallbackSuccess={() => fetchSatLinkedAcount()}
							onCallbackError={() => {
								navigate(location.pathname, {});
							}}
							showEFirmaForm={activeTab !== 'password'}
							sx='p-6'
							buttonLabel={
								activeTab === 'password'
									? 'Vincular mi RFC'
									: 'Vincular mi e.firma'
							}
							onLoadingChange={onLoadingChange}
						/>
					</div>

					{status.status !== SATLinkStatus.DELETED && lastShoppingCart && (
						<div className='mt-4 w-full px-4 py-6 bg-white rounded-lg shadow flex-col justify-start items-start gap-[15px] inline-flex'>
							<Text
								size='body-3'
								weight='regular'
							>
								Resumen de tu compra
							</Text>
							<div className='w-full p-6 bg-white rounded-lg border border-[#e6e6e6] flex-col justify-start items-start gap-4 inline-flex'>
								<div>
									<span className='text-[#080d30] text-sm font-medium font-["Lexend Deca"]'>
										Id de la orden:
									</span>
									<span className='text-[#080d30] text-sm font-light font-["Lexend Deca"]'>
										{' '}
										#{lastShoppingCart?.id || 'Sin información'}
									</span>
								</div>
								<div>
									<span className='text-[#080d30] text-sm font-medium font-["Lexend Deca"]'>
										Servicio:
									</span>
									<span className='text-[#080d30] text-sm font-light font-["Lexend Deca"]'>
										{' '}
										{lastShoppingCart?.shopping_item &&
										lastShoppingCart.shopping_item.length === 1
											? lastShoppingCart.shopping_item[0].purchase_type ===
												'subscription'
												? `Suscripción ${lastShoppingCart.shopping_item[0].product_version.product.name}`
												: lastShoppingCart.shopping_item[0].product_version
														.product.name
											: lastShoppingCart?.shopping_item &&
												  lastShoppingCart?.shopping_item?.length > 1
												? [
														...new Set(
															lastShoppingCart.shopping_item.map((item) => {
																const isSubscription =
																	item.purchase_type === 'subscription';
																const productName =
																	item.product_version.product.name;
																return isSubscription
																	? `Suscripción ${productName}`
																	: productName;
															}),
														),
													].join(', ')
												: 'Sin información'}
									</span>
								</div>
								{lastShoppingCart?.shopping_item?.some(
									(item) => item.product_version.product.id === 101,
								) && (
									<div>
										<div className='text-[#080d30] text-sm font-medium font-["Lexend Deca"]'>
											Periodos:
										</div>
										<div className='text-[#080d30] text-sm font-light font-["Lexend Deca"] flex flex-col gap-2 mt-2'>
											{Object.entries(
												lastShoppingCart.shopping_item
													.filter(
														(item) => item.product_version.product.id === 101,
													)
													.reduce(
														(acc, item) => {
															const year = item.additional_information.year;
															if (!acc[year]) acc[year] = new Set();
															acc[year].add(item.additional_information.month);
															return acc;
														},
														{} as Record<string, Set<number>>,
													),
											).map(([year, months]) => (
												<div
													key={year}
													className='flex items-center gap-2 flex-wrap'
												>
													<div className='text-[#080d30] font-medium'>
														{year}
													</div>
													{Array.from(months)
														.sort((a, b) => a - b)
														.map((month) => {
															const monthName = new Date(2024, month - 1)
																.toLocaleString('es-ES', { month: 'short' })
																.slice(0, 3)
																.replace(/^\w/, (c) => c.toUpperCase());
															return (
																<div
																	key={`${year}-${month}`}
																	className='h-6 px-3 py-1 bg-[#f7f7f7] rounded justify-start items-center gap-2 inline-flex'
																>
																	{monthName}
																</div>
															);
														})}
												</div>
											))}
										</div>
									</div>
								)}
								<div className='h-6 justify-start items-center gap-2 inline-flex'>
									<div className='text-[#080d30] text-sm font-medium font-["Lexend Deca"]'>
										Información de pago:
									</div>
									{lastShoppingCart?.token ? (
										<span className='text-[#080d30] text-sm font-light font-["Lexend Deca"] flex items-center gap-2'>
											{' '}
											{lastShoppingCart?.token?.details?.brand && (
												<img
													src={getBrandIcon(
														lastShoppingCart?.token?.details?.brand,
													)}
													alt='brand'
													className='w-[32px] h-[32px] ml-2'
												/>
											)}
											{lastShoppingCart?.token?.details?.brand
												? lastShoppingCart.token.details.brand
														.charAt(0)
														.toUpperCase() +
													lastShoppingCart.token.details.brand
														.slice(1)
														.toLowerCase()
												: 'Sin información'}
											<span className='text-[#080d30] text-sm font-light font-["Lexend Deca"]'>
												{lastShoppingCart?.token?.details?.last_four &&
													`****${lastShoppingCart?.token?.details?.last_four}`}
											</span>
										</span>
									) : (
										<span className='text-[#080d30] text-sm font-light font-["Lexend Deca"] flex items-center gap-2'>
											{
												<CustomIcon
													sx='ml-0'
													color={'var(--h-primary)'}
													name={purchaseTypeToIcon(
														lastShoppingCart?.transaction?.type,
													)}
													width={40}
												/>
											}{' '}
											{lastShoppingCart?.transaction?.reference ??
												'Sin información'}
										</span>
									)}
								</div>
								<div className='h-6 justify-start items-center gap-2 inline-flex'>
									<div className="text-[#080d30] text-sm font-medium font-['Lexend Deca']">
										Estado:
									</div>
									<div className='px-3 py-1 bg-[#fbe28d] rounded justify-start items-center gap-1 flex'>
										<Warning
											size={16}
											weight='fill'
											color='#62470f'
										/>
										<div className='text-[#62470f] text-sm font-light font-["Lexend Deca"]'>
											{lastShoppingCart?.status === 'pending' &&
											!lastShoppingCart?.token
												? 'Pendiente por pago'
												: 'Pendiente por vincular credenciales'}
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default UnlinkedUser;

import HERU_API from '../shared/constants/heruApi';
import STORAGE_KEYS from '../shared/constants/storage';
import { get, post } from '../shared/utils/FetchSSL.util';
import { useStorage } from '../shared/utils/general.util';

const getOnboardingWelcome = async (message: string, tokenSession?: string) => {
	try {
		const body = {
			message,
		};
		const response = await post(HERU_API.ONBOARDING_WELCOME, body, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

type OnboardingRecommendationBody = {
	recommendation: string;
	userContext: string;
};

const getOnboardingRecommendation = async (
	body: OnboardingRecommendationBody,
	tokenSession?: string,
) => {
	try {
		const response = await post(HERU_API.ONBOARDING_RECOMMENDATION, body, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const getOnboardingIA = async (userId: number, tokenSession?: string) => {
	try {
		const response = await get(
			HERU_API.HERU_API_IA_SAMANTHA_ONBOARDING(userId),
			{
				token: true,
				isNewGateway: true,
				tokenSession,
				directURL: `${import.meta.env.VITE_GCP_API_URL}/api/v1/heru-ai-service/samantha/onboarding/${userId}`,
			},
		);
		return response;
	} catch (error) {
		useStorage().setItem(
			STORAGE_KEYS.ONBOARDING_FAILED_DATE,
			JSON.stringify(new Date()),
		);
		throw error;
	}
};

type ChatIASamanthaBody = {
	userMessage: string;
	userName: string;
	assistantLatestQuestion: string;
	userId: number;
};

const postChatIASamantha = async (
	body: ChatIASamanthaBody,
	tokenSession?: string,
) => {
	try {
		const response = await post(HERU_API.HERU_API_IA_SAMANTHA_CHAT, body, {
			token: true,
			isNewGateway: true,
			tokenSession,
			directURL: `${import.meta.env.VITE_GCP_API_URL}/api/v1/heru-ai-service/samantha/chat`,
		});

		return response;
	} catch (error) {
		useStorage().setItem(
			STORAGE_KEYS.ONBOARDING_FAILED_DATE,
			JSON.stringify(new Date()),
		);
		throw error;
	}
};

const getOnboardingAnalysisIA = async (
	userId: number,
	onboardingId: number,
	tokenSession?: string,
) => {
	try {
		const response = await get(
			HERU_API.HERU_API_IA_SAMANTHA_ANALYSIS(userId, onboardingId),
			{
				token: true,
				isNewGateway: true,
				tokenSession,
				directURL: `${import.meta.env.VITE_GCP_API_URL}/api/v1/heru-ai-service/samantha/onboarding-analizer/${userId}/${onboardingId}`,
			},
		);

		return response;
	} catch (error) {
		useStorage().setItem(
			STORAGE_KEYS.ONBOARDING_FAILED_DATE,
			JSON.stringify(new Date()),
		);
		throw error;
	}
};

export {
	getOnboardingWelcome,
	getOnboardingRecommendation,
	postChatIASamantha,
	getOnboardingIA,
	getOnboardingAnalysisIA,
};

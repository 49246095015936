import React, { useCallback, useEffect, useState } from 'react';

import { CircularProgress, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

//? Types
import { ServiceStatusElement } from '../../@types/ServiceStatus.type';

//? Components
import Text from '../../components/atoms/Text';
import Heading from '../../components/atoms/Heading';
import ActiveServices from './components/organisms/ActiveServices';
import ClosedServices from './components/organisms/ClosedServices';
import { getAllServiceStatus } from '../../services/serviceStatus.service';

//? Services
import { getUserSuscriptions } from '../../services/suscription.service';

//? Redux
import { RootState } from '../../redux/store';
import {
	setServiceStatusElement,
	setUserSubscriptions,
} from '../../redux/slices/profile.slice';
import { ANALYTICS_EVENTS } from '../../shared/constants/events';
import ROUTES from '../../shared/constants/routes';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../shared/utils/growthbook.util';
import FiscalFlowTransactions from '../fiscalFlowTransactions';
import UnlinkedUser from '../home/organisms/UnlinkedUser';
import { SATLinkStatus } from '../../@types/SATLinking';
import useService from '../../hooks/useService';

const SERVICE_STATUS_TABS = ['Compras activas', 'Compras cerradas'];

const ServiceStatus: React.FC = () => {
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();
	const { getSatLinkedAccount, isLoading } = useService();

	const { subscriptions } = useSelector((state: RootState) => state.profile);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const { status } = useSelector((state: RootState) => state.satLinkStatus);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const [selectedCategory, setSelectedCategory] = useState(
		SERVICE_STATUS_TABS[0],
	);
	const [isLoadingServiceStatus, setIsLoadingServiceStatus] = useState(false);
	const [serviceStatus, setServiceStatus] = useState<ServiceStatusElement[]>(
		[],
	);

	const showLucyView = useFeatureIsOn(GROWTHBOOK_FEATURE_FLAGS.LUCY_VIEW);

	const fetchUserSuscriptions = useCallback(async () => {
		try {
			const subs = await getUserSuscriptions(tokenSession);
			dispatch(setUserSubscriptions(subs));
		} catch (error) {
		} finally {
		}
	}, []);

	const fetchServiceStatus = async () => {
		try {
			setIsLoadingServiceStatus(true);
			const resource = await getAllServiceStatus(tokenSession);
			setServiceStatus(resource);
			dispatch(setServiceStatusElement(resource));
		} catch (error) {
		} finally {
			setIsLoadingServiceStatus(false);
		}
	};

	const handleChangeCategory = (item: string) => {
		setSelectedCategory(item);
	};

	useEffect(() => {
		if (userProfile) {
			getSatLinkedAccount(userProfile?.id!, userProfile?.cellphone!);
		}
	}, [userProfile]);

	useEffect(() => {
		fetchUserSuscriptions();
		fetchServiceStatus();
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: ROUTES.SERVICE_STATUS,
		});
	}, []);

	const isMobile = useMediaQuery('(max-width: 768px)');

	if (isLoading.satLinkStatus) {
		return (
			<div className='flex items-center justify-center mt-5'>
				<CircularProgress size={25} />
			</div>
		);
	}

	if (status.status !== SATLinkStatus.ACTIVE) {
		return (
			<div className='mt-5'>
				<UnlinkedUser page='service-status' />
			</div>
		);
	}

	if (showLucyView) {
		return <FiscalFlowTransactions />;
	}

	return (
		<>
			<div className='flex flex-col p-5 lg:p-12'>
				<Heading
					size='s'
					weight='bold'
					color='medium'
					sx='hidden lg:flex'
				>
					Estado de mis compras
				</Heading>
				<Text
					size='body-2'
					weight='bold'
					sx='flex lg:hidden'
				>
					Estado de mis compras
				</Text>

				<>
					<div className='flex flex-row mt-6 mb-6'>
						{SERVICE_STATUS_TABS?.map((item, index) => (
							<div
								key={`category-${index}`}
								className='mr-6'
							>
								<button onClick={() => handleChangeCategory(item)}>
									<Text
										size={isMobile ? 'caption' : 'label'}
										color={selectedCategory === item ? 'primary' : 'dark'}
										weight={selectedCategory === item ? 'bold' : 'medium'}
									>
										{item}
									</Text>
								</button>
								{selectedCategory === item && (
									<div className='h-0.5 bg-h-primary w-full'></div>
								)}
							</div>
						))}
					</div>

					{isLoadingServiceStatus ? (
						<div className='flex items-center justify-center mt-5'>
							<CircularProgress size={25} />
						</div>
					) : (
						<>
							{selectedCategory === SERVICE_STATUS_TABS[0] && (
								<ActiveServices
									subscriptions={subscriptions}
									serviceStatus={serviceStatus}
									fetchServiceStatus={fetchServiceStatus}
								/>
							)}

							{selectedCategory === SERVICE_STATUS_TABS[1] && (
								<ClosedServices
									subscriptions={subscriptions}
									serviceStatus={serviceStatus}
								/>
							)}
						</>
					)}
				</>
			</div>
		</>
	);
};

export default React.memo(ServiceStatus);

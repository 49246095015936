import { useMediaQuery } from '@mui/material';
import Text from '../../atoms/Text';

interface Props {
	options: {
		label: string;
		labelMobile?: string;
		slug: string;
	}[];
	handleCategory: (slug: string) => void;
	currentOption: string;
	sx?: string;
}

const TabSelector: React.FC<Props> = ({
	options,
	handleCategory,
	currentOption,
	sx,
}) => {
	const isMobile = useMediaQuery('(max-width: 1024px)');

	return (
		<div className={`flex items-end ${sx}`}>
			{options.map((option, index) => {
				const isActive = currentOption === option.slug;
				return (
					<>
						<button
							key={`category-${index}`}
							onClick={() => handleCategory(option.slug)}
							className={`transition px-2 py-1 border-b border-b-h-neutral-90 rounded-t-lg group ${
								isActive
									? '!border-h-primary !border-b-2 !px-3 hover:bg-h-primary-95'
									: 'hover:bg-h-neutral-95'
							}`}
						>
							<Text
								weight={'medium'}
								color={isActive ? 'primary' : 'medium'}
								sx={`!cursor-pointer !text-[13px] lg:!text-base !leading-5 !lg:leading-6 whitespace-nowrap transition
								${isActive ? '' : 'opacity-60 group-hover:opacity-80'}
							`}
							>
								{isMobile ? option.labelMobile || option.label : option.label}
							</Text>
						</button>
						{index < options.length - 1 && (
							<div className='h-[1px] bg-h-neutral-90 w-full'></div>
						)}
					</>
				);
			})}
			{!isMobile && (
				<div className='w-full h-[1px] bg-h-neutral-90 hidden lg:block'></div>
			)}
		</div>
	);
};

export default TabSelector;

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import Modal from '../../molecules/Modal/Modal';

import SimpleCheckout from './SimpleCheckout';
import { setShowModalCheckout } from '../../../redux/slices/plans.slice';
import PaymentInstructions from './PaymentInstructions';
import { clearPaymentInstructions } from '../../../redux/slices/cart.slice';
import ROUTES from '../../../shared/constants/routes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LOCAL_STORAGE_KEY from '../../../shared/constants/localStorageKey';

const ModalCheckout = () => {
	const { showModalCheckout } = useSelector((state: RootState) => state.plans);
	const { paymentInstructions } = useSelector((state: RootState) => state.cart);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const onClose = () => {
		dispatch(setShowModalCheckout(false));
		sessionStorage.removeItem(LOCAL_STORAGE_KEY.ANNUAL);
		sessionStorage.removeItem(LOCAL_STORAGE_KEY.CHECKOUT);
		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.delete('annual');
		newSearchParams.delete('checkout');
		newSearchParams.delete('product_added')
		setSearchParams(newSearchParams);
		if (paymentInstructions.show) {
			dispatch(clearPaymentInstructions());
			navigate(ROUTES.ROOT);
		}
	};

	return (
		<Modal
			open={showModalCheckout}
			onClose={onClose}
			sx='!p-0 md:!p-8 w-[94%] h-[90%] md:h-fit overflow-y-auto md:w-4/5 relative max-h-[90%]'
		>
			{paymentInstructions.show ? (
				<PaymentInstructions
					paymentMethod={paymentInstructions.paymentMethod}
					total={paymentInstructions.total}
					reference={paymentInstructions.reference}
				/>
			) : (
				<SimpleCheckout />
			)}
		</Modal>
	);
};

export default ModalCheckout;

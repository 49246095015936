import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Skeleton } from '@mui/material';
import { Info, Trash } from '@phosphor-icons/react';
import * as yup from 'yup';

//? Components
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import ToggleSwitch from '../../atoms/ToggleSwitch';
import Modal from '../../molecules/Modal/Modal';
import CheckoutStripeWidget from '../CheckoutStripeWidget';
//? Share
import LITERALS from '../../../shared/constants/literals';
import {
	formatPrice,
	isSessionStorageEnabled,
	useStorage,
} from '../../../shared/utils/general.util';
import {
	getProductPeriodicityIdBySlug,
	getProductProdByProductVersion,
} from '../../../shared/utils/ecommerce.util';
import { putOrder } from '../../../services/order.service';
//? Redux
import { RootState } from '../../../redux/store';
import {
	clearCart,
	setPaymentInstructions,
	setUserOrder,
} from '../../../redux/slices/cart.slice';
//? Types
import { CategoryName, ProductsCategory } from '../../../@types/Products.type';
import {
	PAYMENT_METHOD,
	PLATFORM_CATALOG,
} from '../../../@types/Checkout.type';
import { ALERT_TYPE } from '../../molecules/Alert';
import { showAlert } from '../../../redux/slices/alert.slice';
import SESSION_STORAGE_KEY from '../../../shared/constants/sessionStorageKey';
import { logClevertapEvent } from '../../../shared/analytics/analytics';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import PaymentMethods from './PaymentMethods';
import PaymentMethodPayPal from '../../../pages/checkout/PaymentMethodPayPal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PlatformOS } from '../../../redux/slices/profile.slice';
import { postCheckoutProcessPayPal } from '../../../services/checkout.service';
import ROUTES from '../../../shared/constants/routes';
import { ProcessV2Payment } from '../../../@types/Payments.types';
import { postShoppingCartProcess } from '../../../services/payments.service';
import LOCAL_STORAGE_KEY from '../../../shared/constants/localStorageKey';
import OrganizationLink from './OrganizationLink';
import { ORGANIZATIONS } from '../../../shared/constants/organizations';
import Tag from '../../molecules/Tag';
import ItemCheckout from './ItemCheckout';
import ItemRegularization from './Items/ItemRegularization';
import ItemAnnualDeclaration from './Items/ItemAnnualDeclaration';
import {
	changeItemShoppingCart,
	getOrderRefresh,
} from '../../../services/orders.service';
import {
	OrderRefreshMapper,
	ProductVersionItem,
	TUserOrder,
} from '../../../@types/Order.type';
import STORAGE_KEYS from '../../../shared/constants/storage';
import URL_PARAMS from '../../../shared/constants/urlParams';
import useAddToCart from '../../../hooks/useAddToCart';
import { PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import { DATES } from '../../../shared/utils/dates';

type CouponForm = {
	coupon_code: string;
};

type TCheckoutRef = {
	childFunction: () => void;
};
export const schemaCoupon = yup
	.object()
	.shape({
		coupon_code: yup.string().required(LITERALS.REQUIRED_FIELD),
	})
	.required(LITERALS.REQUIRED_FIELD);

const SimpleCheckout = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<CouponForm>({
		resolver: yupResolver(schemaCoupon),
		defaultValues: {
			coupon_code: useStorage().getItem(STORAGE_KEYS.COUPON) ?? '',
		},
	});

	const childRef = useRef<TCheckoutRef>(null);
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState({
		coupon: false,
		checkout: false,
		order: false,
	});
	const [planPeriodicity, setPlanPeriodicity] = useState(0);
	const [category, setCategory] = useState<PAYMENT_METHOD>(PAYMENT_METHOD.CARD);
	const [showPaypalModal, setShowPaypalModal] = useState(false);
	const [coupon, setCoupon] = useState('');

	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { utmInfo, orders, userOrder, isLoadingOrder } = useSelector(
		(state: RootState) => state.cart,
	);
	const { products } = useSelector((state: RootState) => state.productList);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const platformOS = useStorage().getItem(STORAGE_KEYS.PLATFORM_OS);

	const channelApp = searchParams.get('platform_os');
	const channelSource = searchParams.get('channel_source');
	const order = userOrder?.[0];

	useEffect(() => {
		if (order) {
			const subscription = order?.shoppingItem?.find(
				(item) =>
					item.productVersion?.product?.category === ProductsCategory.plans,
			);
			setPlanPeriodicity(subscription?.productVersion?.periodicity ?? 0);

			useStorage().setItem(
				LOCAL_STORAGE_KEY.LAST_ORDER_PURCHASE,
				JSON.stringify(order),
			);
		}
	}, [order]);

	useEffect(() => {
		if (isLoadingOrder) {
			setIsLoading((prev) => ({ ...prev, order: true }));
		} else {
			setIsLoading((prev) => ({ ...prev, order: false }));
		}
	}, [isLoadingOrder]);

	const showAlertNotification = (
		type: ALERT_TYPE,
		title: string,
		description: string,
	) => {
		const alert = {
			type,
			title,
			description,
		};
		dispatch(showAlert(alert));
	};

	const handleErrorPayment = (responsePayment?: ProcessV2Payment) => {
		showAlertNotification(
			ALERT_TYPE.ERROR,
			'Aviso',
			responsePayment?.transaction?.failure_reason ??
				'Ocurrió un error con tu pago',
		);
		const eventProperties = {
			event_type: EVENT_TYPES.PLANS_CHARGED,
			Amount: formatPrice(parseInt(order?.total.toString())),
			'Charged ID': order?.id?.toString() ?? '',
			'Payment mode':
				order?.total === 0 ? PAYMENT_METHOD.TRADITIONAL_CASH : category,
			coupon: order?.coupon?.code ?? '',
			error_code: responsePayment?.transaction?.failure_reason ?? 'unknown',
		};
		logClevertapEvent(ANALYTICS_EVENTS.FAIL_CHARGED, eventProperties);
	};

	const handleSuccessfulPurchase = (
		responsePayment: ProcessV2Payment,
		paymentMethod: PAYMENT_METHOD,
	) => {
		const alert = {
			type: ALERT_TYPE.SUCCESS,
			title: 'Aviso',
			description: 'Referencia de pago generada con éxito',
		};
		dispatch(showAlert(alert));

		dispatch(
			setPaymentInstructions({
				paymentMethod,
				show: true,
				total: responsePayment.total,
				reference: responsePayment.transaction.reference ?? '',
				referenceLink:
					responsePayment.transaction.additional_information
						.payment_reference_url ?? '',
				expiresAt:
					responsePayment.transaction.additional_information.expires_at ?? 0,
			}),
		);

		dispatch(setUserOrder([]));
	};

	const getPayloadCheckout = () => {
		const isSessionAvailable = isSessionStorageEnabled();

		const utmCampaign = isSessionAvailable
			? sessionStorage.getItem(LOCAL_STORAGE_KEY.CAMPAIGN)
			: utmInfo?.utm_campaign;
		const utmSource = isSessionAvailable
			? sessionStorage.getItem(LOCAL_STORAGE_KEY.SOURCE)
			: utmInfo?.utm_source;
		const utmMedium = isSessionAvailable
			? sessionStorage.getItem(LOCAL_STORAGE_KEY.MEDIUM)
			: utmInfo?.utm_medium;

		const additionalInformation = {
			utms: {
				utm_campaign: utmCampaign ?? 'organic',
				utm_source: utmSource ?? 'organic',
				utm_medium: utmMedium ?? 'organic',
			},
		};

		let sourceChannel = 'web';
		if (
			channelSource === 'app' ||
			platformOS == PlatformOS.IOS ||
			PlatformOS.ANDROID
		) {
			sourceChannel = channelApp ?? platformOS ?? 'app';
		}

		return {
			payment_method:
				order.total === 0 ? PAYMENT_METHOD.TRADITIONAL_CASH : category,
			source_channel: sourceChannel,
			additional_information: additionalInformation,
		};
	};

	const handleOtherPaymentMethod = async () => {
		try {
			setIsLoading((prev) => ({ ...prev, checkout: true }));
			let responsePayment: ProcessV2Payment;

			const payloadCheckout = getPayloadCheckout();

			responsePayment = await postShoppingCartProcess({
				tokenSession,
				cartId: order.id,
				renewSubscription: false,
				additionalInformation: payloadCheckout.additional_information,
				sourceChannel: payloadCheckout.source_channel,
				paymentMethod: payloadCheckout.payment_method,
				platformId: PLATFORM_CATALOG.STRIPE,
				tokenId: '',
			});

			if (
				(responsePayment?.status === 'processed' &&
					responsePayment?.transaction?.succeed) ||
				responsePayment?.status === 'pending'
			) {
				handleSuccessfulPurchase(
					responsePayment,
					payloadCheckout.payment_method,
				);
			} else {
				handleErrorPayment(responsePayment);
			}
		} catch (error) {
			handleErrorPayment();
		} finally {
			setIsLoading((prev) => ({ ...prev, checkout: false }));
		}
	};

	const handlePay = () => {
		if (childRef.current && category == PAYMENT_METHOD.CARD) {
			childRef.current.childFunction();
			return;
		}
		if (category === PAYMENT_METHOD.PAYPAL && order?.total > 0) {
			setShowPaypalModal(true);
			return;
		}

		if (category !== PAYMENT_METHOD.CARD) {
			handleOtherPaymentMethod();
		}
	};

	const parseUserOrderHandler = (orders: OrderRefreshMapper[]) => {
		const lastOrder = orders.sort((a, b) => b.id - a.id)?.[0];

		const items: ProductVersionItem[] = lastOrder?.shoppingItem.map((item) => {
			const infoProduct = getProductProdByProductVersion(
				products,
				item.productVersionId,
			)!;
			return {
				...item,
				productVersion: {
					product: {
						id: infoProduct.id,
						name: infoProduct.name,
						defaultProductVersionId: infoProduct.default_product_version_id,
						description: infoProduct.description,
						isPurchasable: infoProduct.is_purchasable,
						benefits: infoProduct.benefits,
						slug: infoProduct.slug,
						category: infoProduct.category,
						regime: infoProduct.regime,
					},
					price:
						infoProduct.product_versions.find(
							(version) => version.id === item.productVersionId,
						)?.price ?? 0,
					periodicity:
						infoProduct.product_versions.find(
							(version) => version.id === item.productVersionId,
						)?.periodicity ?? infoProduct.periodicity,
				},
			};
		});

		const newOrder: TUserOrder = {
			...lastOrder,
			shoppingItem: items,
		};

		dispatch(setUserOrder([newOrder]));
	};

	const onPeriodicityToggle = async (isActive: boolean) => {
		const deletedProduct = order.shoppingItem.find(
			(item) => item.productVersion.product.category === CategoryName.Plans,
		);
		if (!deletedProduct) {
			return;
		}
		try {
			setIsLoading((prev) => ({ ...prev, order: true }));

			const productSlug = deletedProduct.productVersion.product.slug;
			const periodicity = planPeriodicity === 30 ? 365 : 30;

			const productVersionId = getProductPeriodicityIdBySlug(
				products,
				productSlug,
				periodicity,
			);

			const body = {
				product_version_id: productVersionId,
			};

			const orderRefresh = await changeItemShoppingCart(
				order.id,
				deletedProduct.id,
				body,
				tokenSession,
			);

			if (orderRefresh) {
				parseUserOrderHandler(orderRefresh);
				setPlanPeriodicity(isActive ? 365 : 30);
			}
		} catch (error) {
			showAlertNotification(
				ALERT_TYPE.ERROR,
				'Aviso',
				'Ocurrió un error, intente más tarde',
			);
		} finally {
			setIsLoading((prev) => ({ ...prev, order: false }));
		}
	};

	const handlePostAddedCupon = () => {
		if (isSessionStorageEnabled()) {
			sessionStorage.removeItem(SESSION_STORAGE_KEY.CUPON_CODE_ADDED);
		} else {
			dispatch(clearCart());
		}
	};

	const getUserOrder = async () => {
		try {
			setIsLoading((prev) => ({ ...prev, order: true }));
			const params = `?status=open&status=failed&is_renewal=false&payment_method=${category}`;
			const ordersResponse = await getOrderRefresh(params, tokenSession);

			if (ordersResponse) {
				parseUserOrderHandler(ordersResponse);
			}
			return ordersResponse;
		} catch (error) {
			return error;
		} finally {
			setIsLoading((prev) => ({ ...prev, order: false }));
		}
	};

	const resetCoupon = () => {
		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.delete(URL_PARAMS.COUPON);
		setSearchParams(newSearchParams);
		setCoupon('');
	};

	const onCouponSubmit: SubmitHandler<CouponForm> = async (body) => {
		try {
			setIsLoading((prev) => ({ ...prev, coupon: true }));

			await putOrder(order.id, body, tokenSession);
			await getUserOrder();

			logClevertapEvent(ANALYTICS_EVENTS.CHECKOUT, {
				event_type: EVENT_TYPES.CHECKOUT_COUPON_VALIDATE,
				success: true,
				coupon: body.coupon_code,
			});
			const alert = {
				type: ALERT_TYPE.SUCCESS,
				title: 'Aviso',
				description: 'Cupón aplicado',
			};
			handlePostAddedCupon();
			dispatch(showAlert(alert));
		} catch (error: any) {
			logClevertapEvent(ANALYTICS_EVENTS.CHECKOUT, {
				event_type: EVENT_TYPES.CHECKOUT_COUPON_VALIDATE,
				success: false,
				coupon: body.coupon_code,
			});
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: 'Aviso',
				description:
					error?.response?.data?.message ?? 'Error, intente más tarde',
			};
			dispatch(showAlert(alert));
		} finally {
			setIsLoading((prev) => ({ ...prev, coupon: false }));
			resetCoupon();
		}
	};

	useEffect(() => {
		// RESET CHECKOUT SEARCH PARAMS
		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.delete(URL_PARAMS.CHECKOUT);
		setSearchParams(newSearchParams);

		// CHECK COUPON
		const coupon = searchParams.get(URL_PARAMS.COUPON);
		if (!order || !coupon) return;
		if (order?.coupon) {
			resetCoupon();
			return;
		}

		if (coupon) {
			onCouponSubmit({ coupon_code: coupon });
		}
	}, [searchParams, order]);

	const checkoutPayPal = async (orderId: number, payloadCheckout: object) => {
		try {
			setIsLoading((prev) => ({ ...prev, checkout: true }));
			const response = await postCheckoutProcessPayPal(
				orderId,
				payloadCheckout,
				tokenSession,
			);
			if (response?.status === 'processed' && response?.transaction?.succeed) {
				setShowPaypalModal(false);
				const alert = {
					type: ALERT_TYPE.SUCCESS,
					title: 'Aviso',
					description: 'Compra realizada',
				};
				dispatch(showAlert(alert));

				useStorage().setItem(
					LOCAL_STORAGE_KEY.LAST_ORDER_PURCHASE,
					JSON.stringify(order),
				);

				const urlParams = new URLSearchParams();
				urlParams.append('paymentMethod', PAYMENT_METHOD.PAYPAL);

				navigate(ROUTES.ONBOARDING_SUCCESSFUL_PURCHASE, {
					replace: true,
				});
			} else {
				const alert = {
					type: ALERT_TYPE.ERROR,
					title: 'Aviso',
					description: response.transaction.message,
				};
				dispatch(showAlert(alert));
			}
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: 'Aviso',
				description: 'Error, intente más tarde',
			};
			dispatch(showAlert(alert));
		} finally {
			setIsLoading((prev) => ({ ...prev, checkout: false }));
		}
	};

	const handlePurchasePayPal = (orderData: any) => {
		let sourceChannel = 'web';
		if (
			channelSource === 'app' ||
			platformOS == PlatformOS.IOS ||
			PlatformOS.ANDROID
		) {
			sourceChannel = channelApp ?? platformOS ?? 'app';
		}
		checkoutPayPal(order.id, {
			payment_method: PAYMENT_METHOD.PAYPAL,
			payment: orderData,
			platform_id: PLATFORM_CATALOG.PAYPAL,
			source_channel: sourceChannel,
		});
	};

	const showAllPaymentsMethod = () => {
		if (order?.shoppingItem) {
			if (order?.shoppingItem.length > 0) {
				const hasSubscription = order?.shoppingItem.filter(
					(item) =>
						item.purchaseType === 'subscription' &&
						item.productVersion.periodicity < 365,
				);
				return hasSubscription.length > 0;
			} else {
				return false;
			}
		}
	};

	useEffect(() => {
		dispatch(
			setPaymentInstructions({
				show: false,
				paymentMethod: PAYMENT_METHOD.CARD,
				total: 0,
				reference: '',
				referenceLink: '',
				expiresAt: 0,
			}),
		);
	}, []);

	const getCodeName = () => {
		const activeOrgs =
			userProfile?.organizations?.filter((org) =>
				[
					ORGANIZATIONS.RAPPI,
					ORGANIZATIONS.DIDI,
					ORGANIZATIONS.UBER,
					ORGANIZATIONS.UBER_EATS,
				]?.some((keyword) => org?.name?.includes(keyword)),
			) ?? [];
		if (activeOrgs.find((org) => org.name.includes('Uber'))) {
			return 'Descuento por Uber';
		}
		if (activeOrgs.length > 0) {
			return `Descuento por ${activeOrgs[0].name}`;
		}
		return 'Descuento';
	};

	const singleSubscription =
		order?.shoppingItem?.length === 1
			? order?.shoppingItem?.find(
					(item) =>
						item.purchaseType === 'subscription' &&
						item.productVersion.product.slug !==
							PRODUCTS_SLUG.ANNUAL_DECLARATION,
				)
			: undefined;

	//@ts-ignore
	const isAnnualSubscription =
		order?.shoppingItem?.length === 1
			? order?.shoppingItem?.find(
					(item) =>
						item.productVersion.product.slug ===
						PRODUCTS_SLUG.ANNUAL_DECLARATION,
				)
			: undefined;

	const showFreeAnnualDeclaration = order?.shoppingItem?.some(
		(item) =>
			item.productVersion.product.category === CategoryName.DeclaraciónAnual &&
			item.total === 0,
	);

	const orderToUse = orders?.[0]?.items?.length > 0 ? orders?.[0] : order;

	//TODO: add the product to the cart
	const annualParam = searchParams.get('annual');
	const productAdded = searchParams.get('product_added');
	const { handleAddCustomProduct } =
		useAddToCart();

	const { products: productsList } = useSelector(
		(state: RootState) => state.productList,
	);

	const regularizationProducts = productsList?.find(
		(product) => product.name === 'Declaración Anual',
	)?.products;

	const subscriptionProduct = regularizationProducts?.find(
		(product) => product.slug === PRODUCTS_SLUG.ANNUAL_DECLARATION,
	);

	const orderTotal = order?.total ?? 0;

	const handleAddToCart = async () => {
		let payload;
		
		if (annualParam === '2024') {
			payload = [{
				product_version_id: subscriptionProduct?.default_product_version_id,
				additional_information: {
					regimes: [],
					year: DATES.currentYear,
				},
			}];
		} else if (productAdded === '31') {
			payload = [{
				product_version_id: 31,
				asat_information: {
					message: 'Agregado desde la página web de heru',
				},
			}];
		} else if (productAdded) {
			payload = [{
				product_version_id: parseInt(productAdded),
				additional_information: {
					regimes: [],
				},
			}];
		}

		if (!payload) return;

		const response = await handleAddCustomProduct({
			payload,
			navigateToCart: false,
			resetOrders: true,
		});
		
		if (!response) return;

		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.delete('annual');
		newSearchParams.delete('product_added');
		newSearchParams.delete('checkout');
		newSearchParams.delete('flow');
		newSearchParams.delete('flow_periodicity');
		setSearchParams(newSearchParams);
	};

	useEffect(() => {
		if (order?.id) {
			if ((annualParam === '2024' && subscriptionProduct?.default_product_version_id) || productAdded) {
				handleAddToCart();
			}
		}
	}, [annualParam, productAdded, subscriptionProduct, order]);

	return (
		<div className='p-4 pb-0 md:p-0'>
			<Modal
				sx='w-full md:w-[500px] h-[500px] overflow-y-auto'
				open={showPaypalModal}
				onClose={() => setShowPaypalModal(false)}
				type='fullscreen'
				children={
					<PaymentMethodPayPal
						order={order}
						purchasePayPal={handlePurchasePayPal}
					/>
				}
			/>
			<div className='w-full'>
				<Text
					size='subtitle'
					sx='mb-6 '
					color='darkMenu'
					weight='bold'
				>
					Activación de servicio
				</Text>
				<div className='flex flex-col-reverse md:flex-row gap-x-6 pb-4'>
					<div className='flex-1'>
						<Text
							size='body-1'
							weight='medium'
							color='darkMenu'
							sx='mb-4 mt-4 md:mt-0'
						>
							Detalle de tu pago
						</Text>

						<CheckoutStripeWidget
							category={category}
							setCategory={setCategory}
							ref={childRef}
							// @ts-ignore
							order={orderToUse}
							setIsLoading={setIsLoading}
						/>

						{!showAllPaymentsMethod() && (
							<PaymentMethods
								category={category}
								setCategory={setCategory}
							/>
						)}
						{order?.total === 0 && (
							<Tag
								type='filled'
								caseVariant='inactive'
								iconLeft={<Info />}
								label='Recuerda que puedes eliminar, modificar o cancelar tus métodos de
							pago y suscripción en cualquier momento'
								sx='mb-4 !rounded py-2'
							/>
						)}
					</div>

					<div className='flex-1 gap-y-6'>
						<Text
							size='body-1'
							weight='medium'
							color='darkMenu'
							sx='mb-4'
						>
							Resumen de tu compra
						</Text>
						{order?.shoppingItem?.length > 0 && (
							<div>
								{!!singleSubscription && (
									<div className='flex gap-x-2 justify-end items-center mb-4'>
										<Text
											size='caption'
											color={'light'}
											weight={'light'}
										>
											Mensual
										</Text>
										<ToggleSwitch
											active={planPeriodicity === 365}
											onToggleChange={onPeriodicityToggle}
											isDisable={isLoading.order}
										/>
										<Text
											size='caption'
											weight={'medium'}
											color={'darkMenu'}
										>
											Anual
										</Text>
									</div>
								)}

								<div className='flex flex-col gap-y-2'>
									<ItemRegularization />
									<ItemAnnualDeclaration />

									{order.shoppingItem
										.filter(
											(item) =>
												item.productVersion.product.category !==
												CategoryName.DeclaraciónAtrasada,
										)
										.map((item) => (
											<ItemCheckout
												key={`item-id-${item.id}`}
												item={item}
												isLoading={isLoading.order}
												planPeriodicity={planPeriodicity}
											/>
										))}
								</div>

								{showFreeAnnualDeclaration && (
									<Text
										size='caption'
										sx='mt-4'
										color='blueLight'
									>
										¡Tu declaración anual está cubierta cuando declaras todo el
										año con Heru!
									</Text>
								)}
							</div>
						)}

						{(order?.coupon || order?.discount > 0) && (
							<div className='flex mt-4 justify-between'>
								{isLoading.order ? (
									<Skeleton
										variant='text'
										sx={{ fontSize: '1rem' }}
										width={150}
									/>
								) : (
									<Text
										size='body-3'
										color='green'
									>
										{order?.coupon?.code ?? getCodeName()}
									</Text>
								)}

								{isLoading.order ? (
									<Skeleton
										variant='text'
										sx={{ fontSize: '1rem' }}
										width={50}
									/>
								) : (
									<div className='flex gap-x-2 items-center'>
										<Text
											size='body-3'
											color='green'
											sx=''
										>
											${formatPrice(order.discount)}
										</Text>
										{order.coupon && (
											<Button
												variant='text'
												label=''
												icon={<Trash />}
												onClick={() => onCouponSubmit({ coupon_code: '' })}
												loading={isLoading.coupon}
											/>
										)}
									</div>
								)}
							</div>
						)}

						<div className='flex mt-6 justify-between'>
							<Text
								size='body-2'
								weight='bold'
								color='darkMenu'
							>
								Total
							</Text>
							{isLoading.order ? (
								<Skeleton
									variant='text'
									sx={{ fontSize: '1rem' }}
									width={50}
								/>
							) : (
								<Text
									size='body-2'
									weight='bold'
									color='darkMenu'
								>
									${formatPrice(orderTotal)}
									{singleSubscription?.productVersion.periodicity === 365 &&
										!order.coupon && <Text>/año</Text>}
								</Text>
							)}
						</div>

						<hr className='my-6' />

						<form onSubmit={handleSubmit(onCouponSubmit)}>
							<div className='flex flex-row gap-x-4 my-4 '>
								<input
									value={coupon}
									type='text'
									className='flex-1 border border-gray-300 p-2 px-4 rounded w-1/2 md:w-fit dark:bg-white'
									placeholder='Código de descuento'
									{...register('coupon_code')}
									onChange={(e) => setCoupon(e.target.value)}
								/>

								<Button
									variant='outline'
									loading={isLoading.coupon}
									disabled={
										!!errors.coupon_code ||
										isLoading.order ||
										isLoading.checkout
									}
									label='Aplicar'
									type='submit'
									sx='!w-fit md:!px-6 !py-0 !text-base !h-auto '
								/>
							</div>
						</form>
						<OrganizationLink />

						<Button
							onClick={() => handlePay()}
							disabled={
								isLoading.checkout ||
								isLoading.order ||
								!order?.shoppingItem?.length
							}
							variant='primary'
							label={`Comprar ${
								singleSubscription
									? singleSubscription?.productVersion?.product?.name.toLocaleLowerCase()
									: ''
							}`}
							sx='!hidden md:!flex h-[47px] mt-10 sticky'
							type='submit'
							loading={isLoading.checkout}
						/>
					</div>
				</div>
				<div className='bg-white pt-4 pb-2 sticky bottom-0 md:!hidden'>
					<Button
						onClick={() => handlePay()}
						disabled={
							isLoading.checkout ||
							isLoading.order ||
							!order?.shoppingItem?.length
						}
						variant='primary'
						label={`Comprar ${
							singleSubscription
								? singleSubscription?.productVersion?.product?.name?.toLocaleLowerCase()
								: ''
						}`}
						sx='!sticky bottom-0  mb-2'
						type='submit'
						loading={isLoading.checkout}
					/>
				</div>
			</div>
		</div>
	);
};

export default SimpleCheckout;

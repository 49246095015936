import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { DATES } from '../../../../shared/utils/dates';
import { nameMonth } from '../../../../shared/utils/formatMonth';

interface Props {
	periods: (month: number) => void;
}

const CalendarFft: React.FC<Props> = ({ periods }) => {
	const [year, setYear] = useState(DATES.currentYear);
	const [periodsSelected, setPeriodsSelected] = useState<
		{ month: number; year: number }[]
	>([]);

	const months = [
		[1, 2, 3, 4],
		[5, 6, 7, 8],
		[9, 10, 11, 12],
	];

	const handleUpdateYear = (type: 'increase' | 'decrease') => {
		if (type === 'decrease' && year > 2020) {
			setYear(year - 1);
		} else if (type === 'increase' && year < DATES.currentYear) {
			setYear(year + 1);
		}
	};

	const handleSelectMonth = (month: number) => {
		const selectedMonth = { month, year };
		setPeriodsSelected([selectedMonth]);
		periods(month);
	};

	const isMonthSelected = (month: number) => {
		return periodsSelected.some((p) => p.month === month && p.year === year);
	};

	return (
		<Box className="flex-col justify-start items-start gap-2 inline-flex">
			<div className="justify-start items-center gap-[172px] inline-flex">
				<CaretLeft
					className="w-8 h-4 relative cursor-pointer"
					onClick={() => handleUpdateYear('decrease')}
				/>
				<Typography className="text-center text-[#080d30] text-sm font-medium font-['Lexend Deca'] leading-none">
					{year}
				</Typography>
				<CaretRight
					className="w-4 h-4 relative cursor-pointer"
					onClick={() => handleUpdateYear('increase')}
				/>
			</div>
			<div className="p-2 flex-col justify-start items-start flex">
				<div className="flex-col justify-start items-start gap-2 flex">
					{months.map((period, index) => (
						<div
							className="self-stretch justify-start items-start gap-2 inline-flex"
							key={index}
						>
							{period.map((month) => (
								<Button
									key={month}
									sx={{
										width: '91px',
										height: '24px',
										margin: '0.25rem',
										textTransform: 'none',
                                        borderRadius: '10px',
										backgroundColor: isMonthSelected(month) ? '#dce7f0' : 'transparent',
										color: isMonthSelected(month) ? '#2e84c5' : '#333333',
										
									}}
									onClick={() => handleSelectMonth(month)}
								>
									<Typography
										className={`text-center text-xs font-normal font-['Lexend Deca'] leading-none ${isMonthSelected(month) ? 'text-[#2e84c5]' : 'text-[#333333]'}`}
									>
										{nameMonth(month)}
									</Typography>
								</Button>
							))}
						</div>
					))}
				</div>
			</div>
		</Box>
	);
};

export default CalendarFft;

import { useEffect, useState } from 'react';
import Text from '../../../components/atoms/Text';
import Tabs from '../../../components/molecules/Tabs';
import SATLinkingForm from '../../../components/molecules/SATLinkingForm';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { linkedAccount, startLoader } from '../../../redux/slices/sat.slice';
import { fetchSATLinkedById } from '../../../services/satLinking.service';
import { RootState } from '../../../redux/store';
import fiscalComplianceBanner from '../../../assets/img/banners/Conoce_planes_Small_Desktop.png';
import bannerSubscriptions from '../../../assets/img/banners/Suscribete_Small_Desktop.png';
import bannerAnnualPromo from '../../../assets/img/png/banner-annual-50.png';
import onboardingBanner from '../../../assets/img/banners/finish_onboarding.png';
import { Warning } from '@phosphor-icons/react';
import { SATLinkStatus } from '../../../@types/SATLinking';
import { ShoppingCartDetail } from '../../../@types/LastSchoppingCart';
import { getLastShoppingCartWithDetail } from '../../../services/shoppingCart.service';
import CustomIcon from '../../../components/atoms/CustomIcon';
import { getBrandIcon } from '../../../shared/utils/payments.util';
import LastPendingOrder from '../../pendingReferences/components/LastPendingOrder';
import PromotionalBanner from '../molecules/PromotionalBanner';
import Slider from 'react-slick';
import { settingsCarrousel } from '../../../components/organisms/SATLinking/SATLinking';
import { PRODUCTS_BY_SLUG } from '../../plan/data/ecommerceData';
import { PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import ROUTES from '../../../shared/constants/routes';
import { isSessionStorageEnabled } from '../../../shared/utils/general.util';
import { setOverwriteUtm, updateUTMs } from '../../../redux/slices/cart.slice';
import { ANALYTICS_EVENTS } from '../../../shared/constants/events';
import { EVENT_TYPES } from '../../../shared/constants/events';
import { logClevertapEvent } from '../../../shared/analytics/analytics';
import LOCAL_STORAGE_KEY from '../../../shared/constants/localStorageKey';
import Modal from '../../../components/molecules/Modal/Modal';

type BannerItem = {
	src: string;
	alt: string;
	onClick: () => void;
};

const items = [
	{
		label: 'Vincular con e.firma',
		slug: 'efirma',
	},
	{
		label: 'Vincular con RFC y contraseña',
		slug: 'password',
	},
];

const UserZero = () => {
	const [showModal, setShowModal] = useState({
		fiscalCompliance: false,
		annualArticle: false,
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showLinkInfo, setShowLinkInfo] = useState(false);
	const [activeTab, setActiveTab] = useState(items[0].slug);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const { status } = useSelector((state: RootState) => state.satLinkStatus);
	const userProfile = useSelector((state: RootState) => state.profile.user);

	const [lastShoppingCart, setLastShoppingCart] =
		useState<ShoppingCartDetail>();

	const fetchSatLinkedAcount = async () => {
		try {
			dispatch(startLoader(true));
			const resource = await fetchSATLinkedById(tokenSession);
			dispatch(linkedAccount(resource));
		} catch (error: any) {
			dispatch(startLoader(false));
		}
	};

	const getLastShoppingCart = async () => {
		try {
			const response = await getLastShoppingCartWithDetail(tokenSession);
			setLastShoppingCart(response);
		} catch (error) {
		}
	};

	const purchaseTypeToIcon = (type: number) => {
		switch (type) {
			case 2:
				return 'oxxo';
			case 3:
				return 'spei';
			case 4:
				return 'paypal';
			default:
				return 'oxxo';
		}
	};

	useEffect(() => {
		if (status.status !== SATLinkStatus.DELETED && userProfile?.id) {
			getLastShoppingCart();
		}
	}, [userProfile]);

    const { activeSuscriptions, hasPendingAnalysis } = useSelector(
		(state: RootState) => state.profile,
	);

    const legacySubscriptions = [
		PRODUCTS_BY_SLUG.HERU_PLUS,
		PRODUCTS_BY_SLUG.ACTIVIDAD_EMPRESARIAL,
		PRODUCTS_BY_SLUG.RESICO,
		PRODUCTS_BY_SLUG.PLATAFORMAS_TECNOLOGICAS,
		PRODUCTS_BY_SLUG.ARR_MONTHLY,
		PRODUCTS_BY_SLUG.PLAN_ENTREPRENEUR,
		PRODUCTS_BY_SLUG.PLAN_PLATFORM,
		PRODUCTS_BY_SLUG.PLAN_BUSINESS,
	];

    const userHaveSubscription = activeSuscriptions?.some((sub) =>
		legacySubscriptions?.includes(
			sub.product_version?.product?.slug as PRODUCTS_BY_SLUG,
		),
	);

    const userHaveAnnualSubscription = activeSuscriptions?.some(
		(sub) =>
			sub.product_version?.product?.slug === PRODUCTS_SLUG.ANNUAL_DECLARATION,
	);

    const logBannerHomeEvent = (banner: 'planes y precios' | 'suscripcion') => {
		logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
			event_type: EVENT_TYPES.HOME_PURCHASE_BANNER_INTERACTION,
			id: userProfile?.id!,
			phone: userProfile?.cellphone!,
			banner,
		});
	};

    const goToPlans = () => {
		logBannerHomeEvent('planes y precios');
		if (isSessionStorageEnabled()) {
			const overwriteUtmKey = LOCAL_STORAGE_KEY.OVERWRITE_UTM;

			let overwriteUtm = sessionStorage.getItem(overwriteUtmKey);

			if (overwriteUtm === null) {
				sessionStorage.setItem(overwriteUtmKey, 'true');
				overwriteUtm = 'true';
			}
		} else {
			const currentOverwriteUtm = useSelector(
				(state: RootState) => state.cart.overwriteUtm,
			);

			if (currentOverwriteUtm === undefined || currentOverwriteUtm === null) {
				dispatch(setOverwriteUtm(true));
			}

			if (currentOverwriteUtm === true) {
			} else {
				const currentUTMs = useSelector(
					(state: RootState) => state.cart.utmInfo,
				);

				if (!currentUTMs) return;
				const updatedUTMs = {
					...currentUTMs,
					utm_campaign: currentUTMs?.utm_campaign,
					utm_medium: currentUTMs?.utm_medium,
					utm_source: currentUTMs?.utm_source,
				};
				dispatch(updateUTMs(updatedUTMs));
			}
		}

		const params = hasPendingAnalysis ? '?show=analisis' : '';
		navigate(`${ROUTES.PLANS_AND_PRICES}${params}`);
	};

    const getActiveBanners = (): BannerItem[] => {
		const banners: BannerItem[] = [];

		banners.push({
			src: fiscalComplianceBanner,
			alt: 'Banner de cumplimiento fiscal',
			onClick: () => setShowModal({ fiscalCompliance: true, annualArticle: false }),
		});

		if (!userHaveSubscription) {
			banners.push({
				src: bannerSubscriptions,
				alt: 'Banner de compra suscripcion',
				onClick: goToPlans,
			});
		}

		if (!userHaveAnnualSubscription) {
			banners.push({
				src: bannerAnnualPromo,
				alt: 'Banner de compra suscripcion anual',
				onClick: () => {
					setShowModal({ fiscalCompliance: false, annualArticle: true });
				},
			});
		}

		if (hasPendingAnalysis) {
			banners.push({
				src: onboardingBanner,
				alt: 'Completa tu análisis fiscal',
				onClick: goToPlans,
			});
		}

		return banners;
	};

	const renderBanners = () => {
		const activeBanners = getActiveBanners();

		if (activeBanners.length === 0) return null;

		if (activeBanners.length === 1) {
			const banner = activeBanners[0];
			return (
				<img
					onClick={banner.onClick}
					src={banner.src}
					alt={banner.alt}
					className='relative h-fit-content w-full object-cover rounded-lg cursor-pointer'
				/>
			);
		}

		return (
			<Slider {...settingsCarrousel}>
				{activeBanners.map((banner, index) => (
					<img
						key={index}
						onClick={banner.onClick}
						src={banner.src}
						alt={banner.alt}
						className='relative h-fit-content w-full object-cover rounded-lg cursor-pointer'
					/>
				))}
			</Slider>
		);
	};

	return (
		<div className='w-full max-w-[800px] mx-auto'>
			<Modal
				open={showModal.fiscalCompliance}
				onClose={() => setShowModal({ fiscalCompliance: false, annualArticle: false })}
				sx='!p-2'
			>
				<div className='!w-[90vw] max-w-[800px] h-[calc(100vh-100px)] pt-6 relative rounded-lg overflow-hidden'>
					<iframe
						title='Embedded Page'
						width='100%'
						height='100%'
						src='https://noticias.heru.app/en/articles/4287105'
						allowFullScreen
					></iframe>
				</div>
			</Modal>
			<Modal
				open={showModal.annualArticle}
				onClose={() => setShowModal({ fiscalCompliance: false, annualArticle: false })}
			>
				<div className='!w-[90vw] max-w-[800px] h-[calc(100vh-100px)] pt-6 relative'>
					<iframe
						title='Embedded Page'
						width='100%'
						height='100%'
						src='https://noticias.heru.app/en/articles/4295297'
						allowFullScreen
					></iframe>
				</div>
			</Modal>
			<PromotionalBanner />
			<div className='mb-4'>
				<LastPendingOrder isSingle={false} />
			</div>
			<div>
				<div className='mb-4 border-2 border-white shadow rounded-[16px] overflow-hidden'>
					{renderBanners()}
				</div>
			</div>

			<div
				onClick={() => setShowLinkInfo(false)}
				className={`mt-6 rounded-lg border-4  border-h-primary shadow-md`}
			>
				{showLinkInfo ? (
					<div>
						<Text
							size='s'
							sx='mb-2 lg:text-xl bg-h-primary text-white p-4'
							weight='medium'
						>
							Vincula aquí tu RFC para calcular tus deducibles e iniciar con tu
							declaración anual
						</Text>
						<Text
							size='xs'
							sx='mb-8 bg-h-primary text-white px-4 pb-4'
							weight='light'
						>
							Para brindarte un mejor servicio, necesitamos acceder a tu
							información fiscal. Tus datos están seguros y solo serán
							utilizados para el cálculo de tus declaraciones.
						</Text>
					</div>
				) : (
					<div>
						<Text
							size='m'
							sx='text-center mt-6 mb-2 mx-4 lg:text-xl flex justify-center items-center'
							weight='medium'
						>
							Vincula tu cuenta fiscal con tu e.firma o RFC
						</Text>
						<Text
							size='s'
							sx='text-center mb-8 mx-4 flex justify-center items-center text-gray-600'
							weight='light'
						>
							El paso más importante para simplificar tus impuestos y lograr
							completa visibilidad de tus datos fiscales es vincular tu cuenta
							fiscal. Este proceso lo puedes hacer mediante tu e.firma o RFC.
						</Text>
					</div>
				)}

				<Tabs
					items={items}
					onClickTab={(val) => setActiveTab(val)}
				/>
				<SATLinkingForm
					onCallbackSuccess={() => fetchSatLinkedAcount()}
					onCallbackError={() => {
						navigate(location.pathname, {});
					}}
					showEFirmaForm={activeTab !== 'password'}
					sx='p-6'
					buttonLabel={
						activeTab === 'password' ? 'Vincular mi RFC' : 'Vincular mi e.firma'
					}
				/>
			</div>

			{status.status !== SATLinkStatus.DELETED && lastShoppingCart && (
				<div className='mt-4 w-full px-4 py-6 bg-white rounded-lg shadow flex-col justify-start items-start gap-[15px] inline-flex'>
					<Text
						size='body-3'
						weight='regular'
					>
						Resumen de tu compra
					</Text>
					<div className='w-full p-6 bg-white rounded-lg border border-[#e6e6e6] flex-col justify-start items-start gap-4 inline-flex'>
						<div>
							<span className='text-[#080d30] text-sm font-medium font-["Lexend Deca"]'>
								Id de la orden:
							</span>
							<span className='text-[#080d30] text-sm font-light font-["Lexend Deca"]'>
								{' '}
								#{lastShoppingCart?.id || 'Sin información'}
							</span>
						</div>
						<div>
							<span className='text-[#080d30] text-sm font-medium font-["Lexend Deca"]'>
								Servicio:
							</span>
							<span className='text-[#080d30] text-sm font-light font-["Lexend Deca"]'>
								{' '}
								{lastShoppingCart?.shopping_item &&
								lastShoppingCart.shopping_item.length === 1
									? lastShoppingCart.shopping_item[0].purchase_type ===
										'subscription'
										? `Suscripción ${lastShoppingCart.shopping_item[0].product_version.product.name}`
										: lastShoppingCart.shopping_item[0].product_version.product
												.name
									: lastShoppingCart?.shopping_item &&
										  lastShoppingCart?.shopping_item?.length > 1
										? [
												...new Set(
													lastShoppingCart.shopping_item.map((item) => {
														const isSubscription =
															item.purchase_type === 'subscription';
														const productName =
															item.product_version.product.name;
														return isSubscription
															? `Suscripción ${productName}`
															: productName;
													}),
												),
											].join(', ')
										: 'Sin información'}
							</span>
						</div>
						{lastShoppingCart?.shopping_item?.some(
							(item) => item.product_version.product.id === 101,
						) && (
							<div>
								<div className='text-[#080d30] text-sm font-medium font-["Lexend Deca"]'>
									Periodos:
								</div>
								<div className='text-[#080d30] text-sm font-light font-["Lexend Deca"] flex flex-col gap-2 mt-2'>
									{Object.entries(
										lastShoppingCart.shopping_item
											.filter((item) => item.product_version.product.id === 101)
											.reduce(
												(acc, item) => {
													const year = item.additional_information.year;
													if (!acc[year]) acc[year] = new Set();
													acc[year].add(item.additional_information.month);
													return acc;
												},
												{} as Record<string, Set<number>>,
											),
									).map(([year, months]) => (
										<div
											key={year}
											className='flex items-center gap-2 flex-wrap'
										>
											<div className='text-[#080d30] font-medium'>{year}</div>
											{Array.from(months)
												.sort((a, b) => a - b)
												.map((month) => {
													const monthName = new Date(2024, month - 1)
														.toLocaleString('es-ES', { month: 'short' })
														.slice(0, 3)
														.replace(/^\w/, (c) => c.toUpperCase());
													return (
														<div
															key={`${year}-${month}`}
															className='h-6 px-3 py-1 bg-[#f7f7f7] rounded justify-start items-center gap-2 inline-flex'
														>
															{monthName}
														</div>
													);
												})}
										</div>
									))}
								</div>
							</div>
						)}
						<div className='h-6 justify-start items-center gap-2 inline-flex'>
							<div className='text-[#080d30] text-sm font-medium font-["Lexend Deca"]'>
								Información de pago:
							</div>
							{lastShoppingCart?.token ? (
								<span className='text-[#080d30] text-sm font-light font-["Lexend Deca"] flex items-center gap-2'>
									{' '}
									{lastShoppingCart?.token?.details?.brand && (
										<img
											src={getBrandIcon(
												lastShoppingCart?.token?.details?.brand,
											)}
											alt='brand'
											className='w-[32px] h-[32px] ml-2'
										/>
									)}
									{lastShoppingCart?.token?.details?.brand
										? lastShoppingCart.token.details.brand
												.charAt(0)
												.toUpperCase() +
											lastShoppingCart.token.details.brand
												.slice(1)
												.toLowerCase()
										: 'Sin información'}
									<span className='text-[#080d30] text-sm font-light font-["Lexend Deca"]'>
										{lastShoppingCart?.token?.details?.last_four &&
											`****${lastShoppingCart?.token?.details?.last_four}`}
									</span>
								</span>
							) : (
								<span className='text-[#080d30] text-sm font-light font-["Lexend Deca"] flex items-center gap-2'>
									{
										<CustomIcon
											sx='ml-0'
											color={'var(--h-primary)'}
											name={purchaseTypeToIcon(
												lastShoppingCart?.transaction?.type,
											)}
											width={40}
										/>
									}{' '}
									{lastShoppingCart?.transaction?.reference ??
										'Sin información'}
								</span>
							)}
						</div>
						<div className='h-6 justify-start items-center gap-2 inline-flex'>
							<div className="text-[#080d30] text-sm font-medium font-['Lexend Deca']">
								Estado:
							</div>
							<div className='px-3 py-1 bg-[#fbe28d] rounded justify-start items-center gap-1 flex'>
								<Warning
									size={16}
									weight='fill'
									color='#62470f'
								/>
								<div className='text-[#62470f] text-sm font-light font-["Lexend Deca"]'>
									{lastShoppingCart?.status === 'pending' &&
									!lastShoppingCart?.token
										? 'Pendiente por pago'
										: 'Pendiente por vincular credenciales'}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default UserZero;

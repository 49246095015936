import HERU_API from '../shared/constants/heruApi';
import { getCustom } from '../shared/utils/FetchSSL.util';

const getFiscalFlowTransactions = async (
	userId: number,
	tokenSession?: string,
) => {
	try {
		const response = await getCustom(HERU_API.HERU_API_FFT(userId), {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const getFftDetails = async (userId: number, tokenSession?: string) => {
	try {
		const response = await getCustom(HERU_API.HERU_API_FFT_DETAILS(userId), {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const getInfoFftProfiles = async (tokenSession?: string) => {
	try {
		const response = await getCustom(HERU_API.HERU_API_FFT_INFO_PROFILES, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const getAllFft = async (
	userId: number,
	tokenSession?: string,
	year?: string,
) => {
	try {
		const response = await getCustom(HERU_API.HERU_API_FFT_ALL(userId, year), {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const getLastFft = async (
	userId: number,
	tokenSession?: string,
) => {
	try {
		const response = await getCustom(HERU_API.HERU_API_FFT_LAST(userId), {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

export {
	getFiscalFlowTransactions,
	getFftDetails,
	getInfoFftProfiles,
	getAllFft,
	getLastFft,
};

import { ArrowRight } from '@phosphor-icons/react';
import { useMediaQuery } from '@mui/material';

import Button from '../../atoms/Button';
import HomeCard from '../../molecules/HomeCard';
import CardDetailFFT from '../CardDetailFFT';
import { useNavigate } from 'react-router-dom';
import { FftItem } from '../../../@types/FFT.type';

interface DeclarationStatusFFTProps {
	declarationStatus: FftItem;
}

const DeclarationStatusFFT = ({
	declarationStatus,
}: DeclarationStatusFFTProps) => {
	const isMobile = useMediaQuery('(max-width: 768px)');
	const navigate = useNavigate();

	const handleGoToAllDeclarations = () => {
		navigate('/fft');
	};

	return (
		<HomeCard
			title='Estado de mis servicios'
			showButton={false}
			href={'/declarations'}
			headerButton={
				<>
					{declarationStatus?.has_more_ffts && (
						<Button
							onClick={() => {
								handleGoToAllDeclarations();
							}}
							type='button'
							label={isMobile ? 'Ver todos' : 'Ver todos mis servicios'}
							variant='text'
							icon={<ArrowRight size={20} />}
							labelColor='primary'
						/>
					)}
				</>
			}
		>
			<CardDetailFFT declaration={declarationStatus} />
		</HomeCard>
	);
};

export default DeclarationStatusFFT;

import { useEffect, useState } from 'react';
import Modal from '../../../components/molecules/Modal/Modal';
import Text from '../../../components/atoms/Text';
import { Check, Info } from '@phosphor-icons/react';
import PrimaryButton from '../../../components/atoms/Button';
import ModalHeader from '../molecules/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import { formatCurrency } from '../../../shared/utils/general.util';
import useAddToCart from '../../../hooks/useAddToCart';
import { DATES } from '../../../shared/utils/dates';
import { setShowModalCheckout } from '../../../redux/slices/plans.slice';
import { getSalesPeriodsAdquired } from '../../../services/sales.service';

const ModalRegularizationsYearly = ({
	isOpen,
	handleClose,
}: {
	isOpen: boolean;
	handleClose: () => void;
}) => {
	const dispatch = useDispatch();
	const { handleAddCustomProduct, isLoading: isLoadingAddToCart } =
		useAddToCart();

	const { products } = useSelector((state: RootState) => state.productList);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const [selectedYears, setSelectedYears] = useState<string[]>([]);
	const [periodsAdquired, setPeriodsAdquired] = useState<any>([]);

	const haveDiscount = false;

	const regularizationProducts = products?.find(
		(product) => product.name === 'Declaración Anual',
	)?.products;

	const product = regularizationProducts?.find(
		(product) => product.slug === PRODUCTS_SLUG.ANNUAL_DECLARATION_HARD,
	);

	const subscriptionProduct = regularizationProducts?.find(
		(product) => product.slug === PRODUCTS_SLUG.ANNUAL_DECLARATION,
	);

	const getPriceForYear = (year: number) => {
		if (selectedYears.length === 0) return 0;
		
		if (year === DATES.currentYear) {
			return subscriptionProduct?.product_versions.find(version => version.id === 130)?.price! / 100;
		}
		return product?.product_versions.find(version => version.id === 45)?.price! / 100;
	};

	const price = (year: number) => {
		const basePrice = getPriceForYear(year);
		return haveDiscount ? Math.round(basePrice * 0.9) : Math.round(basePrice);
	};

	const totalPrice = selectedYears.reduce((total, year) => {
		return total + price(Number(year));
	}, 0);

	const years = Array.from(
		{ length: 4 },
		(_, index) => DATES.currentYear - (index + 2),
	);

	const fetchSalesPeriodsAdquired = async () => {
		try {
			const response = await getSalesPeriodsAdquired({
				tokenSession,
				period_type: 'annual',
			});
			if (!response?.annual) throw new Error('No se encontraron periodos');
			setPeriodsAdquired(response?.annual);
		} catch (error) {}
	};

	const handleAddPeriod = (year: string) => {
		if (selectedYears.includes(year)) {
			setSelectedYears((prev) => prev.filter((m) => m !== year));
		} else {
			setSelectedYears((prev) => [...prev, year]);
		}
	};

	const handleAddToCart = async () => {
		const payload = selectedYears.map((year) => ({
			product_version_id:
				DATES.currentYear === Number(year)
					? subscriptionProduct?.default_product_version_id
					: product?.default_product_version_id,
			additional_information: {
				regimes: [],
				year: Number(year),
			},
		}));

		const response = await handleAddCustomProduct({
			payload,
			navigateToCart: false,
			resetOrders: true,
		});
		if (!response) return;
		handleClose();
		dispatch(setShowModalCheckout(true));
	};

	useEffect(() => {
		fetchSalesPeriodsAdquired();
	}, []);

	return (
		<Modal
			open={isOpen}
			onClose={handleClose}
			showCloseButton={false}
			sx='!p-0 rounded-lg overflow-hidden w-[95%] max-w-md'
		>
			<ModalHeader
				title='Regularizar declaraciones anuales atrasadas'
				description='Selecciona los años en los cuales quieres ponerte al día'
				handleClose={handleClose}
			/>

			<div className='p-4 flex flex-col gap-4 sm:gap-8'>
				<div className='grid grid-cols-3 gap-2'>
					{years.map((year, index) => {
						const isPeriodAdquired = periodsAdquired?.find(
							(period: { origin: string; year: number }) =>
								period.year === year,
						);

						return (
							<button
								key={`year-${index}`}
								className={`rounded-lg px-2 text-sm h-12 ${
									selectedYears.includes(year.toString())
										? 'bg-h-primary-90 border border-h-primary-80 text-h-dark'
										: 'bg-white border border-h-neutral-90 hover:bg-h-neutral-95'
								}
								${isPeriodAdquired ? '!bg-h-success-95 !opacity-100 !text-h-success-30' : ''}
								`}
								onClick={() => handleAddPeriod(year.toString())}
								disabled={isPeriodAdquired}
							>
								{year}
								{isPeriodAdquired && (
									<span className='text-xs w-full rounded-b-lg text-h-success-50 flex items-center gap-1 justify-center'>
										<Check
											className='text-h-success-50 min-w-[10px]'
											weight='bold'
										/>
										Adquirido
									</span>
								)}
							</button>
						);
					})}
				</div>

				<div className='!text-h-neutral-40 p-2 rounded-lg bg-h-neutral-90/50 flex items-start gap-2 sm:-mt-4'>
					<Info className='min-w-5 min-h-5' />
					<div className='flex flex-col gap-1'>
						<Text size='caption'>Importante</Text>
						<Text
							size='mini-1'
							color='medium'
							weight='light'
						>
							Recuerda que para presentar tu declaración anual, todas tus
							declaraciones mensuales deben estar correctamente presentadas. En
							caso de que no lo estén, uno de nuestros contadores se comunicará
							contigo para ayudarte a regularizar o corregir las mismas y así
							poder presentar tu declaración anual.
						</Text>
					</div>
				</div>

				<div className='flex flex-col gap-1'>
					<Text
						size='caption'
						weight='bold'
						sx='flex justify-between'
					>
						<span className='!font-light'>Precio por año</span>{' '}
						<span className='flex flex-col gap-1 items-end'>
							{selectedYears.includes(DATES.currentYear.toString()) ? (
								<>
									${formatCurrency(Math.round(selectedYears.some(year => Number(year) !== DATES.currentYear) ? 
										price(DATES.currentYear - 1) : 
										price(DATES.currentYear)), false)}{' '}
									{selectedYears.some(year => Number(year) !== DATES.currentYear) && (
										<Text size='mini-1' color='medium'>
											(Año 2024: ${formatCurrency(Math.round(price(DATES.currentYear)), false)})
										</Text>
									)}
								</>
							) : (
								`$${formatCurrency(Math.round(price(Number(selectedYears[0]))), false)}`
							)}
							{haveDiscount && (
								<span className='line-through opacity-50'>
									${formatCurrency(getPriceForYear(Number(selectedYears[0])), false)}
								</span>
							)}
						</span>
					</Text>
					<Text
						size='caption'
						weight='bold'
						sx='flex justify-between'
					>
						<span className='!font-light'>Años seleccionados</span>{' '}
						<span>x{selectedYears.length}</span>
					</Text>

					<hr className='my-1.5' />
					<Text
						size='caption'
						sx='flex justify-between'
						weight='bold'
					>
						<span className='!font-light'>Total</span>{' '}
						<span>${formatCurrency(totalPrice, false)}</span>
					</Text>
					<Text
						size='mini-1'
						sx='flex justify-between'
						color='medium'
					>
						*Los descuentos se verán reflejados en la siguiente pantalla
					</Text>
				</div>

				<div className='flex justify-center flex-col sm:flex-row gap-3'>
					<PrimaryButton
						loading={isLoadingAddToCart.products}
						disabled={selectedYears.length === 0}
						onClick={handleAddToCart}
						label={
							haveDiscount
								? `Regularizar ${selectedYears.length} años con 10% de dto`
								: `Regularizar ${selectedYears.length} año`
						}
						sx={`!h-10 sm:!h-12 ${haveDiscount ? '' : ''}`}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ModalRegularizationsYearly;

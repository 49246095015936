import { useSelector } from 'react-redux';
import Heading from '../../components/atoms/Heading';
import FiscalDocumentsSection from './organisms/FiscalDocumentsSection';
import { RootState } from '../../redux/store';
import { useEffect, useState } from 'react';
import Button from '../../components/atoms/Button';
import { Upload, Warning } from '@phosphor-icons/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import AlertBanner from '../../components/molecules/AlertBanner';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../shared/utils/growthbook.util';
import { SATLinkStatus } from '../../@types/SATLinking';
import UnlinkedUser from '../home/organisms/UnlinkedUser';
import useService from '../../hooks/useService';

const FiscalDocuments = () => {
	const [startUpload, setStartUpload] = useState(false);

	const { status } = useSelector((state: RootState) => state.satLinkStatus);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { getSatLinkedAccount } = useService();
	
	const showErrorSatAlert = useFeatureIsOn(
		GROWTHBOOK_FEATURE_FLAGS.SHOW_ERROR_SAT_ALERT,
	);

	useEffect(() => {
		if (userProfile) {
			getSatLinkedAccount(userProfile?.id!, userProfile?.cellphone!);
		}
	}, [userProfile]);

	const isMobile = useMediaQuery('(max-width: 1000px)');

	if (status.status !== SATLinkStatus.ACTIVE) {
		return (
			<div className='mt-5'>
				<UnlinkedUser page='fiscal-documents' />;
			</div>
		);
	}

	if (isMobile) {
		return (
			<section className='p-3 lg:py-6 lg:px-12 flex flex-col gap-4 bg-h-neutral-94 min-h-[calc(100vh-100px)] lg:min-h-[calc(100vh-46px)]'>
				<div className='flex flex-col md:flex-row justify-between items-center gap-4'>
					<Heading
						size='s'
						weight='bold'
						responsive
					>
						Documentos fiscales
					</Heading>
					<Button
						label='Carga tus facturas o retenciones'
						variant='primary'
						onClick={() => setStartUpload(true)}
						sx='w-full md:w-[233px] h-[44px]'
						icon={<Upload size={24} />}
					/>
				</div>

				<FiscalDocumentsSection
					startUpload={startUpload}
					setStartUpload={setStartUpload}
				/>
			</section>
		);
	}
	return (
		<section className='p-3 lg:py-6 lg:px-12 flex flex-col gap-4 bg-h-neutral-94 min-h-[calc(100vh-100px)] lg:min-h-[calc(100vh-46px)]'>
			{showErrorSatAlert && (
				<div className='flex justify-center  items-center mb-2 md:mb-4'>
					<AlertBanner
						variant='error'
						title='Inconveniente temporal en la descarga de retenciones'
						description={
							<div>
								El SAT presenta intermitencias. Carga tus facturas o
								certificados desde el botón{' '}
								<a
									href='/fiscal-documents/'
									className='font-bold '
								>
									"Cargar documentos"
								</a>{' '}
								para generar tu declaración. También puedes consultar nuestro{' '}
								<a
									target='_blank'
									href='https://heru.frontkb.com/en/articles/3578625'
									className='font-bold'
								>
									blog
								</a>{' '}
								para aprender cómo subir los documentos fácilmente.
							</div>
						}
						icon={
							<Warning
								size={36}
								weight='fill'
								color='var(--h-hot-sale) '
								className='flex-2'
							/>
						}
						sx='w-full lg:w-[600px]'
						sizeTitle='body-2'
						sizeDescription='body-2'
					/>
				</div>
			)}
			<div className='flex flex-col md:flex-row justify-between items-center gap-4'>
				<Heading
					size='s'
					weight='bold'
					responsive
				>
					Documentos fiscales
				</Heading>
				<Button
					label='Carga tus facturas o retenciones'
					variant='primary'
					onClick={() => setStartUpload(true)}
					sx='w-full md:w-[233px] h-[44px]'
				/>
			</div>

			<FiscalDocumentsSection
				startUpload={startUpload}
				setStartUpload={setStartUpload}
			/>
		</section>
	);
};

export default FiscalDocuments;

import { useEffect, useState } from "react";
import { DATES } from "../../../shared/utils/dates";

interface DiscountPeriod {
    description: string;
    descriptionCountdown: string;
    discount: number;
}

interface CountdownTime {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export const getDiscountPeriod = (): DiscountPeriod => {
    const currentDate = new Date();
    const currentYear = DATES.currentYear;

    const jan31 = new Date(currentYear, 0, 31); // Enero 31
    const feb28 = new Date(currentYear, 1, 28); // Febrero 28
    const mar31 = new Date(currentYear, 2, 31); // Marzo 31
    const apr10 = new Date(currentYear, 3, 10); // Abril 10

    if (currentDate <= jan31) {
        return {
            description: 'Hasta el 31 de enero tienes un 50% de dto',
            descriptionCountdown: 'para asegurar un 50% de descuento',
            discount: 0.5,
        };
    } else if (currentDate <= feb28) {
        return {
            description: 'Hasta el 28 de febrero tienes un 30% de dto',
            descriptionCountdown: 'para asegurar un 30% de descuento',
            discount: 0.3,
        };
    } else if (currentDate <= mar31) {
        return {
            description: 'Hasta el 31 de marzo tienes un 20% de dto',
            descriptionCountdown: 'para asegurar un 20% de descuento',
            discount: 0.2,
        };
    } else if (currentDate <= apr10) {
        return {
            description: 'Hasta el 10 de abril tienes un 10% de dto',
            descriptionCountdown: 'para asegurar un 10% de descuento',
            discount: 0.1,
        };
    }

    return {
        description: '',
        descriptionCountdown: '',
        discount: 0,
    };
};

export const getCountdownToCurrentDiscount = (): CountdownTime => {
    const currentDate = new Date();
    const currentYear = DATES.currentYear;

    // Definir fechas límite
    const jan31 = new Date(currentYear, 0, 31, 23, 59, 59);
    const feb28 = new Date(currentYear, 1, 28, 23, 59, 59);
    const mar31 = new Date(currentYear, 2, 31, 23, 59, 59);
    const apr10 = new Date(currentYear, 3, 10, 23, 59, 59);

    // Determinar la fecha límite actual
    let targetDate: Date;
    if (currentDate <= jan31) {
        targetDate = jan31;
    } else if (currentDate <= feb28) {
        targetDate = feb28;
    } else if (currentDate <= mar31) {
        targetDate = mar31;
    } else if (currentDate <= apr10) {
        targetDate = apr10;
    } else {
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    // Calcular la diferencia
    const timeLeft = targetDate.getTime() - currentDate.getTime();

    if (timeLeft <= 0) {
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    // Convertir a días, horas, minutos y segundos
    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
};

const AnnualCountdown = ({ page }: { page: 'home' | 'annual_plans' }) => {
    const [isLoading, setIsLoading] = useState({
        countdown: true,
    });
    const [countdown, setCountdown] = useState<CountdownTime>({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    const styles: { [key: string]: { container: string, text_number: string, text_unit: string, description: string } } = {
        home: { container: 'border-2 border-white shadow w-full mx-auto rounded-lg p-2 mb-4 fade-in flex justify-center items-center gap-2 flex-col md:flex-row',
                text_number: 'text-3xl font-bold text-h-primary',
                text_unit: 'text-[9px] uppercase',
                description: 'text-h-dark text-center text-base md:text-xl'
         },
        annual_plans: { container: 'bg-h-red-50/10 max-w-[400px] mx-auto rounded-lg p-2 mb-4 fade-in flex flex-col gap-2 items-center',
            text_number: 'text-3xl font-bold text-h-red-50',
            text_unit: 'text-[9px] uppercase',
            description: 'text-h-red-50 text-center'
        },
    }
    const copies: { [key: string]: { description: string, descriptionCountdown: string } } = {
        home: {
            description: 'Te quedan ',
            descriptionCountdown: getDiscountPeriod().descriptionCountdown + ' en tu anual 2025'
        },
        annual_plans: {
            description: 'Te quedan ',
            descriptionCountdown: getDiscountPeriod().descriptionCountdown
        }
    }
    useEffect(() => {
        setTimeout(() => {
            setIsLoading({ countdown: false });
        }, 1000);

        const timer = setInterval(() => {
            setCountdown(getCountdownToCurrentDiscount());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    if (isLoading.countdown) { return null }
    return (
        <div className={styles[page].container}
        > 
            <p className={styles[page].description}>{copies[page].description}</p>
            <div className='flex items-center justify-center gap-2'>
                <p className='flex flex-col items-center justify-center w-16'>
                    <span className={styles[page].text_number}>
                        {countdown.days}
                    </span>
                    <span className={styles[page].text_unit}>Días</span>
                </p>
                <div className={styles[page].text_number}>:</div>
                <p className='flex flex-col items-center justify-center w-16'>
                    <span className={styles[page].text_number}>
                        {countdown.hours}
                    </span>
                    <span className={styles[page].text_unit}>Horas</span>
                </p>
                <div className={styles[page].text_number}>:</div>
                <p className='flex flex-col items-center justify-center w-16'>
                    <span className={styles[page].text_number}>
                        {countdown.minutes}
                    </span>
                    <span className={styles[page].text_unit}>Minutos</span>
                </p>
                <div className={styles[page].text_number}>:</div>
                <p className='flex flex-col items-center justify-center w-16'>
                    <span className={styles[page].text_number}>
                        {countdown.seconds}
                    </span>
                    <span className={styles[page].text_unit}>Segundos</span>
                </p>
            </div>
            <p className={styles[page].description}>
                {copies[page].descriptionCountdown}
            </p>
        </div >
    );
};
export default AnnualCountdown;
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { getAllFft } from '../../services/fft.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Heading from '../../components/atoms/Heading';
import {
	CalendarBlank,
	FunnelSimple,
	CaretDown,
	CaretUp,
	Files,
	FileX,
	WarningCircle,
	ArrowCounterClockwise,
} from '@phosphor-icons/react';
import Text from '../../components/atoms/Text';
import { Skeleton, useTheme, useMediaQuery } from '@mui/material';
import { AllFft } from '../../@types/FFT.type';
import StatusIcon from './components/molecules/StatusIcon';
import { ALERT_TYPE } from '../../components/molecules/Alert';
import { showAlert } from '../../redux/slices/alert.slice';
import CalendarFft from './components/molecules/CalendarFft';
import CardFft from './components/molecules/CartFft';

import { monthNames } from '../../shared/constants/dates';
import Tag from '../../components/molecules/Tag';
import TabSelector from '../../components/molecules/TabSelector';
import PrimaryButton from '../../components/atoms/Button';
import { DATES } from '../../shared/utils/dates';

const FiscalFlowTransactions: React.FC = () => {
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const dispatch = useDispatch();
	const theme = useTheme();
	const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

	const [isLoadingAllFFT, setIsLoadingAllFFT] = useState(false);
	const [allLucyFft, setAllLucyFft] = useState<AllFft>({
		platec: {
			montly: [],
			annual: [],
		},
		sue_sal: {
			montly: [],
			annual: [],
		},
		resico: {
			montly: [],
			annual: [],
		},
		act_emp: {
			montly: [],
			annual: [],
		},
		rif: {
			montly: [],
			annual: [],
		},
		arriendos: {
			montly: [],
			annual: [],
		},
		asats: {
			montly: [],
			annual: [],
		},
	});
	const [allFFT, setAllFFT] = useState<AllFft>({
		platec: {
			montly: [],
			annual: [],
		},
		sue_sal: {
			annual: [],
		},
		resico: {
			montly: [],
			annual: [],
		},
		act_emp: {
			montly: [],
			annual: [],
		},
		rif: {
			montly: [],
			annual: [],
		},
		arriendos: {
			montly: [],
			annual: [],
		},
		asats: {
			montly: [],
			annual: [],
		},
	});
	const [year, setYear] = useState(String(DATES.currentYear));
	const [expandedCards, setExpandedCards] = useState<{
		[key: string]: boolean;
	}>({});

	const [isOpenFilter, setIsOpenFilter] = useState(false);
	const [isOpenDate, setIsOpenDate] = useState(false);
	const [isDisabledDownloadAll, setIsDisabledDownloadAll] = useState(true);

	const filtersRef = useRef<HTMLDivElement | null>(null);
	const dateRef = useRef<HTMLDivElement | null>(null);
	const [areFiltersActive, setAreFiltersActive] = useState(false);

	const generateLastFiveYears = () => {
		const currentYear = new Date().getFullYear();
		const nextYear = currentYear + 1;

		const years = Array.from({ length: 7 }, (_, index) =>
			(nextYear - index).toString(),
		);

		return years;
	};

	const yearOptions = useMemo(() => generateLastFiveYears(), []);

	const toggleDropdown = () => {
		setIsOpenFilter(!isOpenFilter);
		setIsOpenDate(false);
	};

	const toggleDateDropdown = () => {
		setIsOpenDate(!isOpenDate);
		setIsOpenFilter(false);
	};

	const getFFTs = async (userId: number, year: string) => {
		try {
			setIsLoadingAllFFT(true);
			const response = await getAllFft(userId, tokenSession, year);
			if (response?.message) {
				setAllFFT({
					platec: { montly: [], annual: [] },
					sue_sal: { annual: [] },
					resico: { montly: [], annual: [] },
					act_emp: { montly: [], annual: [] },
					rif: { montly: [], annual: [] },
					arriendos: { montly: [], annual: [] },
					asats: { montly: [], annual: [] },
				});
				const alert = {
					type: ALERT_TYPE.ERROR,
					title: 'Aviso',
					description: response.resource.message,
				};
				dispatch(showAlert(alert));
				setIsDisabledDownloadAll(true);
			} else {
				setAllLucyFft(response);
				setAllFFT(response);
				setIsDisabledDownloadAll(false);
			}
		} catch (error) {}
		setIsLoadingAllFFT(false);
	};

	const handleYearChange = (selectedYear: string) => {
		setYear(selectedYear);
	};

	const toggleCard = (period: string) => {
		setExpandedCards((prev) => {
			const newState = { ...prev };

			newState[period] = prev[period] === undefined ? false : !prev[period];

			return newState;
		});
	};

	const handleDownload = (url: string) => {
		window.open(url, '_blank');
	};

	const handlePeriods = (month: number) => {
		const filteredFft: AllFft = {
			platec: { montly: [], annual: [] },
			sue_sal: { annual: [] },
			resico: { montly: [], annual: [] },
			act_emp: { montly: [], annual: [] },
			rif: { montly: [], annual: [] },
			arriendos: { montly: [], annual: [] },
			asats: { montly: [], annual: [] },
		};

		Object.keys(allLucyFft).forEach((regime) => {
			filteredFft[regime as keyof AllFft].montly = allLucyFft[
				regime as keyof AllFft
			]?.montly?.filter((item: any) => {
				const [itemYear, itemMonth] = item.period.split('-').map(Number);
				return itemYear === Number(year) && itemMonth === month;
			});

			filteredFft[regime as keyof AllFft].annual = [];
		});

		setAllFFT(filteredFft);
		setAreFiltersActive(true);
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (
			filtersRef.current &&
			!filtersRef.current.contains(event.target as Node)
		) {
			setIsOpenFilter(false);
		}
		if (dateRef.current && !dateRef.current.contains(event.target as Node)) {
			setIsOpenDate(false);
		}
	};

	const handleDownloadAllCompleted = () => {
		Object.values(allFFT).forEach((regimeData: any) => {
			if (regimeData.montly && Array.isArray(regimeData.montly)) {
				regimeData.montly
					.filter(
						(item: any) =>
							item.status === 'completed' &&
							item.additional_information?.normal &&
							item.period.startsWith(year),
					)
					.forEach((fft: any) => {
						window.open(fft.additional_information.normal, '_blank');
					});
			}
			if (regimeData.annual && Array.isArray(regimeData.annual)) {
				regimeData.annual
					.filter(
						(item: any) =>
							item.status === 'completed' &&
							item.additional_information?.normal &&
							item.period.startsWith(year),
					)
					.forEach((fft: any) => {
						window.open(fft.additional_information.normal, '_blank');
					});
			}
		});
	};

	const getAllPeriods = (selectedYear: string) => {
		const allPeriods = new Set<string>();

		Object.entries(allFFT || {}).forEach(([, regimeData]: [string, any]) => {
			if (regimeData?.montly?.length > 0) {
				regimeData.montly.forEach((item: any) => {
					if (
						item.period.startsWith(selectedYear) &&
						item.status !== 'missing'
					) {
						allPeriods.add(item.period);
					}
				});
			}

			if (regimeData?.annual?.length > 0) {
				regimeData.annual.forEach((item: any) => {
					if (
						item.period.startsWith(selectedYear) &&
						item.status !== 'missing'
					) {
						allPeriods.add(item.period);
					}
				});
			}
		});

		return Array.from(allPeriods).sort((a, b) => {
			const [yearA, monthA] = a.split('-').map(Number);
			const [yearB, monthB] = b.split('-').map(Number);
			if (yearA !== yearB) return yearB - yearA;
			return monthB - monthA;
		});
	};

	const getDeclarationsCountForPeriod = (period: string) => {
		return Object.values(allFFT).reduce((count, regimeData) => {
			let totalCount = 0;

			// Contar declaraciones mensuales
			if (
				regimeData &&
				typeof regimeData === 'object' &&
				'montly' in regimeData
			) {
				totalCount +=
					regimeData.montly?.filter(
						(item: any) => item.period === period && item.status !== 'missing',
					).length || 0;
			}

			// Contar declaraciones anuales
			if (
				regimeData &&
				typeof regimeData === 'object' &&
				'annual' in regimeData
			) {
				totalCount +=
					regimeData.annual?.filter(
						(item: any) => item.period === period && item.status !== 'missing',
					).length || 0;
			}

			return count + totalCount;
		}, 0);
	};

	const periods = getAllPeriods(year);
	const hasNonMissingDeclarations = periods.length > 0;

	const handleGeneralFilters = (status: string) => {
		let filteredFft: AllFft = {
			platec: { montly: [], annual: [] },
			sue_sal: { annual: [] },
			resico: { montly: [], annual: [] },
			act_emp: { montly: [], annual: [] },
			rif: { montly: [], annual: [] },
			arriendos: { montly: [], annual: [] },
			asats: { montly: [], annual: [] },
		};

		Object.keys(allLucyFft).forEach((regime) => {
			if (status === 'blocked') {
				filteredFft[regime as keyof AllFft].montly = allLucyFft[
					regime as keyof AllFft
				]?.montly?.filter(
					(item: any) => item.is_manual && item.status !== 'completed',
				);

				filteredFft[regime as keyof AllFft].annual = allLucyFft[
					regime as keyof AllFft
				]?.annual?.filter(
					(item: any) => item.is_manual && item.status !== 'completed',
				);
			} else if (status === 'in_progress') {
				filteredFft[regime as keyof AllFft].montly = allLucyFft[
					regime as keyof AllFft
				]?.montly?.filter(
					(item: any) =>
						item.status === 'in_progress' || item.status === 'closed',
				);

				filteredFft[regime as keyof AllFft].annual = allLucyFft[
					regime as keyof AllFft
				]?.annual?.filter(
					(item: any) =>
						item.status === 'in_progress' || item.status === 'closed',
				);
			} else {
				filteredFft[regime as keyof AllFft].montly = allLucyFft[
					regime as keyof AllFft
				]?.montly?.filter((item: any) => item.status === status);

				filteredFft[regime as keyof AllFft].annual = allLucyFft[
					regime as keyof AllFft
				]?.annual?.filter((item: any) => item.status === status);
			}
		});

		setAllFFT(filteredFft);
		setAreFiltersActive(true);
	};

	const handleClearFilters = () => {
		setAllFFT(allLucyFft);
		setAreFiltersActive(false);
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (userProfile?.id) {
			getFFTs(userProfile?.id, String(year));
		}
	}, [userProfile?.id, year]);

	useEffect(() => {
		const periods = getAllPeriods(year);
		const initialExpandedState = periods.reduce(
			(acc, period) => {
				acc[period] = true;
				return acc;
			},
			{} as { [key: string]: boolean },
		);
		setExpandedCards(initialExpandedState);
	}, [allFFT]);

	useEffect(() => {
		let shouldDisable = true;

		const hasCompletedDeclarations = Object.values(allFFT || {}).some(
			(regimeArray) =>
				regimeArray?.montly?.some(
					(item: any) =>
						item.status === 'completed' && item.additional_information?.normal,
				),
		);
		shouldDisable = !hasCompletedDeclarations;

		setIsDisabledDownloadAll(shouldDisable);
	}, [allFFT]);

	const hasAnyDeclarations = useMemo(() => {
		const hasAnnualDeclarations = Object.values(allFFT).some(
			(data: any) =>
				Array.isArray(data.annual) &&
				data.annual.some(
					(item: any) =>
						item?.status === 'pending' || item?.status === 'in_progress',
				),
		);

		return hasAnnualDeclarations || hasNonMissingDeclarations;
	}, [allFFT, hasNonMissingDeclarations]);

	return (
		<>
			<div className='lg:pr-[2.5rem] lg:pl-[3rem] p-4 sticky bg-[#fbfcfd] top-11 z-50 flex items-center mb-6 lg:mb-10'>
				<Heading
					color='medium'
					size='xs'
					weight='bold'
					sx='hidden lg:block'
				>
					Estado de mis servicios
				</Heading>
				<Text
					color='medium'
					size='body-2'
					weight='bold'
					sx='block lg:hidden'
				>
					Estado de mis servicios
				</Text>
			</div>
			<div className='flex flex-col p-5 lg:pr-[2.5rem] lg:pl-[3rem]'>
				<div className='flex lg:gap-4 items-center justify-start lg:justify-between mb-4'>
					<div className='flex flex-row gap-4 items-center'>
						{isLoadingAllFFT ? (
							<div className='hidden lg:block'>
								<Skeleton
									width={isLargeScreen ? 50 : 80}
									height={40}
									sx={{
										borderRadius: '10px',
									}}
								/>
							</div>
						) : (
							<div className='relative hidden text-left lg:inline-block'>
								<Tag
									caseVariant='loading'
									iconLeft={<CalendarBlank size={20} />}
									label='Fecha'
									onClick={toggleDateDropdown}
									type='filled'
									sx='h-10 shadow'
								/>
								{/* Menú de fecha */}
								{isOpenDate && (
									<div
										ref={dateRef}
										className='absolute mt-2 rounded-lg border border-[#cccccc] shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
									>
										<div className='h-48 px-4 py-4 flex-col justify-start items-start inline-flex'>
											<CalendarFft periods={handlePeriods} />
										</div>
									</div>
								)}
							</div>
						)}
						{isLoadingAllFFT ? (
							<div className='hidden lg:block'>
								<Skeleton
									width={isLargeScreen ? 50 : 80}
									height={40}
									sx={{
										borderRadius: '10px',
									}}
								/>
							</div>
						) : (
							<div className='relative hidden text-left lg:inline-block'>
								<Tag
									caseVariant='loading'
									iconLeft={<FunnelSimple size={20} />}
									label='Filtros'
									onClick={toggleDropdown}
									type='filled'
									sx='h-10 shadow'
								/>
								{/* Menú de filtros */}
								{isOpenFilter && (
									<div
										ref={filtersRef}
										className='absolute mt-2 px-2 rounded-lg border border-[#cccccc] shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
									>
										<div className='h-auto px-2 bg-white flex-col justify-start items-start inline-flex'>
											<Text
												size='caption'
												weight='regular'
												color='darkBlue'
												sx='mb-2'
											>
												Filtros generales
											</Text>
											<div
												onClick={() => handleGeneralFilters('blocked')}
												className='cursor-pointer self-stretch px-1 py-2.5 border-t border-[#cccccc] justify-start items-center gap-2 inline-flex'
											>
												<StatusIcon status='blocked' />
												<Text
													size='body-3'
													weight='light'
													color='medium'
												>
													Bloqueados
												</Text>
											</div>

											<div
												onClick={() => handleGeneralFilters('in_progress')}
												className='cursor-pointer self-stretch px-1 py-2.5 border-t border-[#cccccc] justify-start items-center gap-2 inline-flex'
											>
												<StatusIcon status='in_progress' />
												<Text
													size='body-3'
													weight='light'
													color='medium'
												>
													En proceso
												</Text>
											</div>

											<div
												onClick={() => handleGeneralFilters('completed')}
												className='cursor-pointer self-stretch px-1 py-2.5 border-t border-[#cccccc] justify-start items-center gap-2 inline-flex'
											>
												<StatusIcon status='completed' />
												<Text
													size='body-3'
													weight='light'
													color='medium'
												>
													Finalizadas
												</Text>
											</div>

											<div
												onClick={() => handleGeneralFilters('pending')}
												className='cursor-pointer self-stretch px-1 py-2.5 border-t border-[#cccccc] justify-start items-center gap-2 inline-flex'
											>
												<StatusIcon status='pending' />
												<Text
													size='body-3'
													weight='light'
													color='medium'
												>
													Pendientes
												</Text>
											</div>
										</div>
									</div>
								)}
							</div>
						)}
						{isLoadingAllFFT ? (
							<div className='hidden lg:block'>
								<Skeleton
									width={isLargeScreen ? 70 : 80}
									height={40}
									sx={{
										borderRadius: '10px',
									}}
								/>
							</div>
						) : (
							<div className='relative hidden text-left lg:inline-block'>
								<Tag
									caseVariant='inactive'
									iconLeft={<ArrowCounterClockwise size={20} />}
									label='Limpiar filtros'
									onClick={handleClearFilters}
									type='filled'
									sx={`h-10 shadow ${!areFiltersActive ? 'opacity-50 cursor-not-allowed' : ''}`}
									disabled={!areFiltersActive}
								/>
							</div>
						)}
					</div>
					{isLoadingAllFFT ? (
						<div className='w-full lg:w-auto flex justify-between lg:flex-row items-center gap-2'>
							{[1, 2, 3, 4, 5, 6, 7].map((index) => (
								<Skeleton
									key={index}
									width={isLargeScreen ? 50 : '100%'}
									height={40}
									sx={{
										borderRadius: '10px',
										marginBottom: !isLargeScreen ? '8px' : '0',
										flexGrow: !isLargeScreen ? 1 : 0,
									}}
								/>
							))}
						</div>
					) : (
						<>
							<div className='lg:inline-flex hidden h-6 justify-end items-center gap-[5px]'>
								{yearOptions.map((yearOption) => (
									<Tag
										isSelected={year === yearOption}
										key={yearOption}
										caseVariant='filter'
										type='filled'
										label={yearOption}
										onClick={() => handleYearChange(yearOption)}
										sx={`cursor-pointer ${year === yearOption ? 'bg-[#dce7f0] text-[#2e84c5]' : 'bg-[#f7f7f7] text-[#333333]'}`}
									/>
								))}
							</div>
							<div className='w-full lg:hidden flex justify-center'>
								<TabSelector
									options={yearOptions.map((yearOption) => ({
										label: yearOption,
										slug: yearOption,
									}))}
									handleCategory={(val) => handleYearChange(val)}
									currentOption={year}
									sx='w-full justify-between'
								/>
							</div>
						</>
					)}
				</div>
				<div className='h-[auto] p-0 lg:p-4 bg-transparent lg:bg-white rounded-lg lg:shadow flex-col justify-start items-start gap-6 inline-flex'>
					<div className='self-stretch h-[auto] flex-col gap-4 flex'>
						{
							<div className='flex lg:flex-row flex-col justify-between items-center'>
								<div className='self-stretch justify-start items-center gap-2.5 inline-flex'>
									{isLoadingAllFFT ? (
										<Skeleton
											width={isLargeScreen ? 200 : 100}
											height={40}
											sx={{
												borderRadius: '10px',
											}}
										/>
									) : (
										<Text
											size={isLargeScreen ? 'body-1' : 'body-3'}
											weight='bold'
											color='darkBlue'
											sx={isLargeScreen ? '' : 'mb-4'}
										>
											Año {year}
										</Text>
									)}
									<div className='hidden lg:inline-flex vertical-line w-0.5 h-6 bg-[#cccccc]'></div>
									{isLoadingAllFFT ? (
										<div className='hidden lg:block'>
											<Skeleton
												width={isLargeScreen ? 200 : 100}
												height={40}
												sx={{
													borderRadius: '10px',
												}}
											/>
										</div>
									) : (
										<div className='hidden lg:flex flex-row items-center gap-2'>
											<Files
												size={24}
												color='#1790EC'
											/>
											<Text
												color='medium'
												size='body-3'
												weight='regular'
											>
												{Object.values(allFFT || {}).reduce(
													(count, regimeData: any) => {
														let totalCount = 0;

														// Conteo de declaraciones mensuales
														if (
															regimeData &&
															typeof regimeData === 'object' &&
															'montly' in regimeData
														) {
															totalCount +=
																regimeData.montly?.filter(
																	(item: any) =>
																		// Verifica el estado
																		item.status === 'completed' &&
																		item.status !== 'missing' &&
																		// Verifica que el periodo comience con el año seleccionado
																		item.period.startsWith(year) &&
																		item.operation_type !== 'annual',
																).length || 0;
														}

														// Conteo de declaraciones anuales
														if (
															regimeData &&
															typeof regimeData === 'object' &&
															'annual' in regimeData
														) {
															totalCount +=
																regimeData.annual?.filter(
																	(item: any) =>
																		// Verifica el estado
																		item.status === 'completed' &&
																		item.status !== 'missing' &&
																		// Verifica que el periodo comience con el año seleccionado
																		item.period.startsWith(year),
																).length || 0;
														}

														return count + totalCount;
													},
													0,
												)}{' '}
												{''}
												Declaraciones presentadas
											</Text>
										</div>
									)}
									<div className='hidden lg:inline-flex vertical-line w-0.5 h-6 bg-[#cccccc]'></div>
									{isLoadingAllFFT ? (
										<div className='hidden lg:block'>
											<Skeleton
												width={isLargeScreen ? 200 : 100}
												height={40}
												sx={{
													borderRadius: '10px',
												}}
											/>
										</div>
									) : (
										<div className='hidden lg:flex flex-row items-center gap-2'>
											<FileX
												size={24}
												color='#1790EC'
											/>
											<Text
												color='medium'
												size='body-3'
												weight='regular'
											>
												{Object.values(allFFT || {}).reduce(
													(count, regimeData: any) => {
														let totalCount = 0;

														// Conteo de declaraciones mensuales
														if (
															regimeData &&
															typeof regimeData === 'object' &&
															'montly' in regimeData
														) {
															totalCount +=
																regimeData.montly?.filter(
																	(item: any) =>
																		// Verifica el estado
																		(item.status === 'pending' ||
																			item.status === 'in_progress' ||
																			item.status === 'closed') &&
																		item.status !== 'missing' &&
																		// Verifica que el periodo comience con el año seleccionado
																		item.period.startsWith(year) &&
																		item.operation_type !== 'annual',
																).length || 0;
														}

														// Conteo de declaraciones anuales
														if (
															regimeData &&
															typeof regimeData === 'object' &&
															'annual' in regimeData
														) {
															totalCount +=
																regimeData.annual?.filter(
																	(item: any) =>
																		// Verifica el estado
																		(item.status === 'pending' ||
																			item.status === 'in_progress' ||
																			item.status === 'closed') &&
																		item.status !== 'missing' &&
																		// Verifica que el periodo comience con el año seleccionado
																		item.period.startsWith(year),
																).length || 0;
														}

														return count + totalCount;
													},
													0,
												)}{' '}
												{''}
												Declaraciones pendientes
											</Text>
										</div>
									)}
								</div>

								{isLoadingAllFFT ? (
									<Skeleton
										width={isLargeScreen ? 250 : '100%'}
										height={60}
										sx={{
											borderRadius: '10px',
										}}
									/>
								) : (
									<PrimaryButton
										disabled={isDisabledDownloadAll}
										variant='secondary'
										label='Descargar todas mis declaraciones '
										onClick={handleDownloadAllCompleted}
										sx={`w-full lg:w-auto px-4 !disabled:bg-red-500 !disabled:text-white !disabled:cursor-not-allowed ${!hasNonMissingDeclarations ? 'hidden' : ''}`}
									/>
								)}
							</div>
						}
					</div>

					{isLoadingAllFFT ? (
						<div className='w-full'>
							{[...Array(12)].map((_, index) => (
								<div
									key={index}
									className='mb-4'
								>
									<Skeleton
										width='100%'
										height={isLargeScreen ? '60px' : '40px'}
										sx={{
											borderRadius: '10px',
											marginBottom: '8px',
										}}
									/>
									<div className='w-full grid grid-cols-1 lg:grid-cols-3 gap-4'>
										{[1, 2, 3].map((subIndex) => (
											<Skeleton
												key={`${index}-${subIndex}`}
												width='100%'
												height={isLargeScreen ? '146px' : '68px'}
												sx={{
													borderRadius: '10px',
												}}
											/>
										))}
									</div>
								</div>
							))}
						</div>
					) : hasAnyDeclarations ? (
						<>
							{hasNonMissingDeclarations &&
								periods.map((period) => {
									const [year, month] = period.split('-');
									const monthName = monthNames[parseInt(month) - 1];
									const isExpanded = expandedCards[period] ?? true;
									const declarationsCount =
										getDeclarationsCountForPeriod(period);

									return (
										<div
											key={period}
											className='bg-[#f9f9f9] lg:bg-transparent w-full lg:px-6 px-3 py-4 rounded-lg border border-[#e6e6e6] flex-col justify-start items-start gap-4 inline-flex mb-2 shadow-[0px_1px_3px_0px_rgba(0,0,0,0.1),0px_1px_2px_0px_rgba(0,0,0,0.06)] lg:shadow-none'
										>
											<div
												className='self-stretch justify-between items-center gap-2.5 inline-flex cursor-pointer'
												onClick={() => toggleCard(period)}
											>
												{isLoadingAllFFT ? (
													<div className='flex flex-row gap-2 items-center'>
														<Skeleton
															width={isLargeScreen ? 220 : 120}
															height={40}
															sx={{
																borderRadius: '10px',
															}}
														/>
														-
														<Skeleton
															width={isLargeScreen ? 220 : 120}
															height={40}
															sx={{
																borderRadius: '10px',
															}}
														/>
													</div>
												) : (
													<div className='flex flex-row gap-2 items-center'>
														<Text
															size={isLargeScreen ? 'body-2' : 'body-3'}
															weight='bold'
															color='darkBlue'
														>
															{monthName} {year}
														</Text>
														-
														<Text
															size={isLargeScreen ? 'body-3' : 'caption'}
															weight='regular'
															color='medium'
														>
															{declarationsCount}{' '}
															{declarationsCount === 1
																? 'Declaración'
																: 'Declaraciones'}
														</Text>
													</div>
												)}
												{isExpanded ? (
													<CaretUp size={24} />
												) : (
													<CaretDown size={24} />
												)}
											</div>
											{isExpanded && (
												<>
													<div className='self-stretch h-[1px] border border-[#e0e0e0]'></div>
													{isLoadingAllFFT ? (
														<div className='w-full grid grid-cols-1 lg:grid-cols-3 gap-4'>
															{[1, 2, 3].map((index) => (
																<Skeleton
																	key={index}
																	width='100%'
																	height={isLargeScreen ? '146px' : '68px'}
																	sx={{
																		borderRadius: '10px',
																	}}
																/>
															))}
														</div>
													) : (
														<div className='w-full grid grid-cols-1 lg:grid-cols-3 gap-4'>
															{(Object.entries(allFFT) as [string, any][])
																.map(([regime, regimeData]) => {
																	if (!regimeData?.montly) return null;

																	const monthlyItems = regimeData.montly
																		?.filter(
																			(fft: any) => fft.status !== 'missing',
																		)
																		.find(
																			(fft: any) =>
																				fft.period === period && 'id' in fft,
																		);

																	const annualItems = regimeData.annual
																		?.filter(
																			(fft: any) => fft.status !== 'missing',
																		)
																		.find(
																			(fft: any) =>
																				fft.period === period && 'id' in fft,
																		);

																	const allItems = [
																		...(monthlyItems ? [monthlyItems] : []),
																		...(annualItems ? [annualItems] : []),
																	];

																	return allItems.map((item) => (
																		<div
																			key={`${regime}-${period}-${item.id}`}
																			className='w-full'
																		>
																			<CardFft
																				item={item}
																				monthName={monthName}
																				year={year}
																				handleDownload={handleDownload}
																			/>
																		</div>
																	));
																})
																.filter(Boolean)}
														</div>
													)}
												</>
											)}
										</div>
									);
								})}
						</>
					) : (
						<div className='w-full h-[calc(90vh-200px)] flex justify-center items-center'>
							<div className='flex flex-col items-center gap-6'>
								<WarningCircle
									weight='light'
									size={isLargeScreen ? 80 : 70}
									color='#b3b3b3'
								/>
								<Heading
									size='xs'
									weight='medium'
									color='light'
									sx='hidden lg:block'
								>
									No encontramos declaraciones pendientes contratadas con heru para este periodo
								</Heading>
								<Text
									size='body-2'
									weight='medium'
									color='light'
									sx='block text-center lg:hidden'
								>
									No encontramos declaraciones pendientes contratadas con heru para este periodo
								</Text>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default React.memo(FiscalFlowTransactions);
